import React, { useState } from 'react';
import './CookieConsentPopup.css';
import glovar from './glovar';
import CookiePolicy from './CookiePolicy';
import SearchAssetFront from './SearchAssetFront';
import ShowAssetsSummary from './ShowAssetsSummary';
import ShowPopNewsletter from './ShowPopNewsletter';
import HeaderMenu from './HeaderMenu';
import FooterMenu from './FooterMenu';
import { Image } from "@chakra-ui/image";
import eventBus from "../eventBus";
import { SimpleGrid, GridItem, Text, Box, Stack, VStack, HStack,  Spacer, Flex } from "@chakra-ui/layout";
import i18n from "../../i18n"


const  ShowLanding = ({ textType}) => {



   { // terms
      if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        
      return (
        <div>
        <HeaderMenu showSearch={true}/>
     
        <Box backgroundColor="#FFFFFF" /* minHeight="30em" */ minWidth="100%" pb={"0px"}>         
        <SimpleGrid   columns={14} gap={0} p={0} height="maxHeight" backgroundColor="#FFFFFF">
          <GridItem colSpan={14}  backgroundColor="#F8F8F8"  paddingBottom={0}>
            <VStack width="100%" flexDirection="column" justifyContent="center" mt={2}>
         
            <Text textAlign="center" fontSize="36"  p={3} textColor="black" fontFamily="Tahoma" 
            lineHeight="short" 
            fontWeight="semibold" > 
             {i18n.t("busca_tu_prox")}
             <Text textAlign="center" p={2} pl={8} pr={8} fontSize="22" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
                       {i18n.t("encuentra_los")}    
                       </Text>
             </Text>
  
   <Box>
                  
           <button 
           _hover={{ cursor: "pointer" }} 
          onClick= {() =>
           {
            try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
            } catch (exx) { /*console.log("petada event:"+exx)*/}
            
            
              window.location.replace('/home')
           }
           }
           style={{ paddingLeft:40, paddingRight:40, textAlign:"center", fontSize:"large", marginTop:"20px", marginBottom:"20px",
            background: "black", color:"white", height:"50px"    }}>
           Start now, it's free!</button>         
           </Box>
  
           <Image 
           style={{ marginTop:50, paddingBottom:100, resizeMode: 'contain', }} 
           src={`${process.env.PUBLIC_URL}/assets/landmob00.gif`} w={"95%"}  
           //_hover={{ cursor: "pointer" }}
           onClick= {() =>
            {
             try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
             } catch (exx) { /*console.log("petada event:"+exx)*/}
             
             
               //window.location.replace('/home')
            }
            }
           // onClick= {() =>  {}} 
           />
  
  
           </VStack>
   
   
   
        
         
     </GridItem>
   
     <GridItem colSpan={14}  backgroundColor="#F8F8F8"  paddingBottom={10}>
            <VStack width="100%" flexDirection="column" justifyContent="center" mt={2}>
         
            <Text textAlign="center" fontSize="28"  p={3} textColor="black" fontFamily="Tahoma" 
            lineHeight="short" 
            fontWeight="semibold" > 
            The perfect tools, always at your disposal
             <Text textAlign="center" p={2} pl={8} pr={8} fontSize="18" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
             From advanced tech analytics and next-gen backtesting tools to portfolio management, 
             they empower you to make confident investment decisions.
                       </Text>
             </Text>
  
  
           <Image 
           style={{ marginTop:20, paddingBottom:100, resizeMode: 'contain', }} 
           src={`${process.env.PUBLIC_URL}/assets/landmob01.gif`} w={"95%"}  
           //_hover={{ cursor: "pointer" }}
            onClick= {() =>
            {
             try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
             } catch (exx) { /*console.log("petada event:"+exx)*/}
             
             
              // window.location.replace('/home')
            }
            }
           />
  
  
           </VStack>
   
   
   
        
         
     </GridItem>


     <GridItem colSpan={14}  backgroundColor="#F8F8F8"  paddingBottom={0}>
            <VStack width="100%" flexDirection="column" justifyContent="center" mt={2}>
         
            <Text textAlign="center" fontSize="28"  p={3} textColor="black" fontFamily="Tahoma" 
            lineHeight="short" 
            fontWeight="semibold" > 
            All Your Financial Insights, One Platform
             <Text textAlign="center" p={2} pl={8} pr={8} fontSize="18" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
             Access thousands of quotes and finance info across stocks, indices, currencies, commodities, cryptos... 
             everything you need to analyze and invest with ease.
                       </Text>
             </Text>
  
  
           <Image 
           style={{ marginTop:20, paddingBottom:100, resizeMode: 'contain', }} 
           src={`${process.env.PUBLIC_URL}/assets/landmob02.gif`} w={"90%"}  
           //_hover={{ cursor: "pointer" }}
            onClick= {() =>
            {
             try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
             } catch (exx) { /*console.log("petada event:"+exx)*/}
             
             
               //window.location.replace('/home')
            }
            }
           />
  
  
           </VStack>
         
     </GridItem>
     
        </SimpleGrid>

        <VStack width="100%" flexDirection="column" justifyContent="center" mt={0} backgroundColor="#F8F8F8" pb={40}>
       
       <Text textAlign="center" fontSize="24"  p={3} textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
       What are you waiting for?
        </Text>

<Box>
             
<button 
           _hover={{ cursor: "pointer" }} 
          onClick= {() =>
           {
            try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
            } catch (exx) { /*console.log("petada event:"+exx)*/}
            
            
              window.location.replace('/home')
           }
           }
           style={{ paddingLeft:40, paddingRight:40, textAlign:"center", fontSize:"large", marginTop:"0px", marginBottom:"20px",
            background: "black", color:"white", height:"50px"    }}>
           Start now, it's free!</button>                
      </Box>


      </VStack>
      </Box> 
  
     <Box marginBottom={40} marginTop={0}>
     <ShowPopNewsletter />
     </Box>
     <FooterMenu/>
        </div>
      );
      } 
      else {
        return (
          <div>
      
      
      <HeaderMenu showSearch={true}/>
   
      <Box backgroundColor="#FFFFFF" /* minHeight="30em" */ minWidth="100%" pb={"0px"}>         
      <SimpleGrid   columns={14} gap={0} p={0} height="maxHeight" backgroundColor="#FFFFFF">
        <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={0} mt={0} paddingBottom={10}>
          <VStack width="100%" flexDirection="column" justifyContent="center" mt={6}>
       
          <Text textAlign="center" fontSize="42"  p={3} textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
           {i18n.t("busca_tu_prox")}
           <Text textAlign="center" mt={0} fontSize="24" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
                     {i18n.t("encuentra_los")}    
                     </Text>
           </Text>

 <Box>
                
         <button 
         _hover={{ cursor: "pointer" }} 
        onClick= {() =>
         {
          try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
          } catch (exx) { /*console.log("petada event:"+exx)*/}
          
          
            window.location.replace('/home')
         }
         }
         style={{ paddingLeft:40, paddingRight:40, textAlign:"center", 
          fontSize: "1.2rem",
           marginTop:"40px", marginBottom:"40px",
          background: "black", color:"white", height:"54px"    }}>
         Start now, it's free!</button>         
         </Box>

         <Image 
         style={{ marginTop:50, paddingBottom:100, resizeMode: 'contain', }} 
         src={`${process.env.PUBLIC_URL}/assets/landing00b.gif`} w={"80%"}  
         //_hover={{ cursor: "pointer" }}
         // onClick= {() =>  {}} 
         />


         </VStack>
 
 
 
      
       
   </GridItem>
 

   <GridItem colSpan={14}  backgroundColor="#F8F8F8"  paddingBottom={10}>
            <VStack width="100%" flexDirection="column" justifyContent="center" mt={10}>
         
            <Text textAlign="center" fontSize="30"  p={3} textColor="black" fontFamily="Tahoma" 
            lineHeight="short" 
            fontWeight="semibold" > 
            Unlock the power of our advanced tools
             <Text textAlign="center" p={2} ml="20%" mr="20%" fontSize="20" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
             From advanced tech analytics and next-gen backtesting tools to portfolio management, 
             they empower you to make confident investment decisions.
                       </Text>
             </Text>
  
        
  
           <Image 
           style={{ marginTop:20, paddingBottom:100, resizeMode: 'contain', }} 
           src={`${process.env.PUBLIC_URL}/assets/chart001.gif`} w={"70%"} //w={"80%"}  
          //_hover={{ cursor: "pointer" }}
            onClick= {() =>
            {
             try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
             } catch (exx) { /*console.log("petada event:"+exx)*/}
             
             
               //window.location.replace('/home')
            }
            }
           />
  
  
           </VStack>
         
     </GridItem>


      </SimpleGrid>


      <SimpleGrid   columns={14} gap={0} height="maxHeight" backgroundColor="#FFFFFF">
        <GridItem colSpan={7} rounded={10} backgroundColor="#F8F8F8" m={0} mt={0} paddingBottom={10} pt={10} pr={8}>
          <VStack flexDirection="column" justifyContent="center" mt={6}  ml={20}>
       
          <Text textAlign="center" fontSize="28"  p={3} textColor="black" fontFamily="Tahoma" 
            lineHeight="short" 
            fontWeight="semibold" > 
            Maximize Your Portfolio Performance
             <Text textAlign="center" p={2} ml="5%" mr="5%" fontSize="20" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
             Track your investments effortlessly with Pro1X: monitor key metrics, analyze profitability, and review detailed history for informed decision-making.
                       </Text>
             </Text>

         <Image 
         style={{ marginTop:20,  paddingBottom:100, resizeMode: 'contain', }} 
         src={`${process.env.PUBLIC_URL}/assets/landing002.gif`} w={"95%"}  
         //_hover={{ cursor: "pointer" }}
         // onClick= {() =>  {}} 
         />


         </VStack>
 
 
 
      
       
   </GridItem>
 

   <GridItem colSpan={7}  backgroundColor="#F8F8F8"  paddingBottom={10} pt={10} pl={8}>
            <VStack  flexDirection="column" justifyContent="center" mt={6} mr={20}>
         
  
             <Text textAlign="center" fontSize="28"  p={3} textColor="black" fontFamily="Tahoma" 
            lineHeight="short" 
            fontWeight="semibold" > 
            All Your Financial Insights, One Platform
             <Text textAlign="center" p={2} ml="5%" mr="5%" fontSize="20" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
Access thousands of quotes and finance info across stocks, indices, currencies, commodities, cryptos... everything you need to analyze and invest with ease.
                       </Text>
             </Text>
          
  
           <Image 
           style={{ marginTop:20, paddingBottom:100, marginRight:20,  }} 
           src={`${process.env.PUBLIC_URL}/assets/landing003.gif`} w={"95%"}  
           //_hover={{ cursor: "pointer" }}
            onClick= {() =>
            {
             try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
             } catch (exx) { /*console.log("petada event:"+exx)*/}
             
             
               //window.location.replace('/home')
            }
            }
           />
  
  
           </VStack>
         
     </GridItem>


      </SimpleGrid>

      <VStack width="100%" flexDirection="column" justifyContent="center" mt={0} backgroundColor="#F8F8F8" pb={40}>
       
          <Text textAlign="center" fontSize="28"  p={3} textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
          What are you waiting for?
           </Text>

 <Box>
                
         <button 
         _hover={{ cursor: "pointer" }} 
        onClick= {() =>
         {
          try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
          } catch (exx) { /*console.log("petada event:"+exx)*/}
          
          
            window.location.replace('/home')
         }
         }
         style={{ paddingLeft:40, paddingRight:40, textAlign:"center", 
          fontSize: "1.2rem",
           marginTop:"0px", marginBottom:"40px",
          background: "black", color:"white", height:"54px"    }}>
         Start now, it's free!</button>         
         </Box>


         </VStack>

    </Box> 

   <Box marginBottom={40} marginTop={0}>
   <ShowPopNewsletter/>
   </Box>
   <FooterMenu/>
      </div>
        );
      }
      }

  }
  
  export default ShowLanding;