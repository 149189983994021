import "./ToptraderList_styles.scss";
import { useState, useRef  } from "react";

import './search.css';

import { SimpleGrid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";

import { IconButton,  Image, Container } from '@chakra-ui/react';


import { Table, Thead, Tbody, Tr, Th, Td, Select,  InputGroup, InputRightAddon, TableCaption,
 CircularProgress,CircularProgressLabel,Center } from '@chakra-ui/react';

import { Box } from "@chakra-ui/layout";
//import { FiPlus,FiEdit,FiCopy,FiTrash2 } from "react-icons/fi";
import { HStack } from "@chakra-ui/layout";

import { VStack } from "@chakra-ui/layout";
import { Input } from '@chakra-ui/react'
import { Flex } from "@chakra-ui/layout";


import i18n from "../../i18n"

import glovar from './glovar';

import emailjs from "@emailjs/browser"
import eventBus from "../eventBus";
import { getMONEDADivisa } from './glovar'
import { useFetchTodoBT } from "../utils/useFetchTodoBT";

import "./styles.css";

var SHOWselectedItemBUY="";
var SHOWselectedConditionBUY="";
var SHOWselectedItemSELL="";
var SHOWselectedConditionSELL="";
var SHOWselectedBacktestTIME="";

export default ({fitxaDesk, ticker,symbol,element}) => {


  var TICKER_STOCK="";
  var SYMBOL_STOCK="";

  var COLOR_ALCISTA='#1FB31F';
  var COLOR_BAJISTA='#D61A1A';


  try {
    if(ticker!=null) {
      TICKER_STOCK=ticker;
    }
  }
  catch(Exx) {}

  try {
    if(symbol!=null) {
      SYMBOL_STOCK=symbol;
    }
  }
  catch(Exx) {}



  var moneda="$"
  try { moneda=getMONEDADivisa(element.currency,element.quoteType); } catch(Exx) {}
  //console.log("moneda:"+moneda);


  const [doScrollMobile,setScrollMobile] = useState(false);
  const [doScrollDesktop,setScrollDesktop] = useState(false);

  const [isLoadingBT, setIsLoadingBT] = useState(false);

  const [showBackTest,setshowBackTest] = useState(false);

  const [showPopPup,setshowPopPup] = useState(false);

  const [selectedItemBUY, setSelectedItemBUY] = useState('');

  const handleSelectChangeBUY = (event) => {
    //shouldRenderConditionBUYSelect=true;
    if(showErrorSubs) { setshowErrorSubs(false); }
    if(showBackTest) { setshowBackTest(false)}
    setSelectedItemBUY(event.target.value);
    setSelectedConditionBUY(conditionsByIndicatorBUY[event.target.value][0]);
    
  };

  const [selectedConditionBUY, setSelectedConditionBUY] = useState('');

  
  

  const handleSelectChangeConditionBUY = (event) => {
    if(showBackTest) { setshowBackTest(false)}
    setSelectedConditionBUY(event.target.value);
    //console.log("selectedConditionBUY:"+selectedConditionBUY)
  };

 


  const [selectedItemSELL, setSelectedItemSELL] = useState('');

  const handleSelectChangeSELL = (event) => {
    if(showBackTest) { setshowBackTest(false)}
    setSelectedItemSELL(event.target.value);
    setSelectedConditionSELL(conditionsByIndicatorSELL[event.target.value][0]);
  };

  const [selectedConditionSELL, setSelectedConditionSELL] = useState('');

  const handleSelectChangeConditionSELL = (event) => {
    if(showBackTest) { setshowBackTest(false)}
    setSelectedConditionSELL(event.target.value);
  };


   // Resetear el valor de 'Condition' si no hay un indicador seleccionado
   /*if (!selectedItemBUY) {
    setSelectedItemBUY('');
  }
  if (!selectedItemSELL) {
    setSelectedItemSELL('');
  }*/

  const parsingValue3D = (value) => {
    // Limitar el valor de numberOfMonths al rango de 1 a 999
    if (isNaN(value)) {
    //value = 1;
    } 
    else if (value < 1) {
    value = 1;
    } else if (value > 999) {
    value = 999;
    }
  return(value)
}

const parsingValue2D = (value) => {

  if (isNaN(value)) {
  } 
  else if (value < 1) {
  value = 1;
  } else if (value > 99) {
  value = 99;
  }
return(value)
}


const [sesis1Buy, setsesis1Buy] = useState(1);
  const handleSESSIS1Buy = (event) => {
    //let value = parseInt(event.target.value, 10);
    //value=parsingValue2D(value);
    let value = 0; //parseInt(event.target.value, 10);
    if (event.target.value.length > 2) { value = event.target.value.slice(0, 2); }
    else {
      value = parseInt(event.target.value, 10);
    }
    if(showBackTest) { setshowBackTest(false)}
    //setMA1Buy(isNaN(value) ? 0 : value);
    setsesis1Buy(value);
  };

  const [sesis1Sell, setsesis1Sell] = useState(1);
  const handleSESSIS1Sell = (event) => {
    let value = 0; //parseInt(event.target.value, 10);
    if (event.target.value.length > 2) { value = event.target.value.slice(0, 2); }
    else {
      value = parseInt(event.target.value, 10);
    }
    //let value = parseInt(event.target.value, 10);
    //value=parsingValue2D(value);
    if(showBackTest) { setshowBackTest(false)}
    //setMA1Buy(isNaN(value) ? 0 : value);
    setsesis1Sell(value);
  };



  const [MA1Buy, setMA1Buy] = useState(20);
  const handleMA1Buy = (event) => {
    let value = 0; //parseInt(event.target.value, 10);
    if (event.target.value.length > 3) { value = event.target.value.slice(0, 3); }
    else {
      value = parseInt(event.target.value, 10);
    }
    if(showBackTest) { setshowBackTest(false)}
    //setMA1Buy(isNaN(value) ? 0 : value);
    setMA1Buy(value);
  };

  const [MA2Buy, setMA2Buy] = useState(50);
  const handleMA2Buy = (event) => {
    let value = 0; //parseInt(event.target.value, 10);
    if (event.target.value.length > 3) { value = event.target.value.slice(0, 3); }
    else {
      value = parseInt(event.target.value, 10);
    }
    if(showBackTest) { setshowBackTest(false)}
    //setMA2Buy(isNaN(value) ? 0 : value);
    setMA2Buy(value);
  };

  const [MA1Sell, setMA1Sell] = useState(20);
  const handleMA1Sell = (event) => {
    //let value = parseInt(event.target.value, 10);
    //value=parsingValue3D(value);
    let value = 0; //parseInt(event.target.value, 10);
    if (event.target.value.length > 3) { value = event.target.value.slice(0, 3); }
    else {
      value = parseInt(event.target.value, 10);
    }

    if(showBackTest) { setshowBackTest(false)}
    //setMA1Sell(isNaN(value) ? 0 : value);
    setMA1Sell(value);
  };

  const [MA2Sell, setMA2Sell] = useState(50);
  const handleMA2Sell = (event) => {
    //let value = parseInt(event.target.value, 10);
    //value=parsingValue3D(value);
    let value = 0; //parseInt(event.target.value, 10);

    if (event.target.value.length > 3) { value = event.target.value.slice(0, 3); }
    else {
      value = parseInt(event.target.value, 10);
    }

    if(showBackTest) { setshowBackTest(false)}
    //setMA2Sell(isNaN(value) ? 0 : value);
    setMA2Sell(value);
  };


  const [RSI1Buy, setRSI1Buy] = useState(60);
  const handleRSI1Buy = (event) => {

    let value = 0; //parseInt(event.target.value, 10);

    if (event.target.value.length > 2) { value = event.target.value.slice(0, 2); }
    else {
      value = parseInt(event.target.value, 10);
    }
    
    //value=parsingValue2D(value);
    if(showBackTest) { setshowBackTest(false)}
    //setMA1Buy(isNaN(value) ? 0 : value);
    setRSI1Buy(value);
  };

  const [RSI1Sell, setRSI1Sell] = useState(60);
  const handleRSI1Sell = (event) => {

    let value = 0; //parseInt(event.target.value, 10);

    if (event.target.value.length > 2) { value = event.target.value.slice(0, 2); }
    else {
      value = parseInt(event.target.value, 10);
    }

    //let value = parseInt(event.target.value, 10);
    //value=parsingValue2D(value);

    if(showBackTest) { setshowBackTest(false)}
    //setMA1Buy(isNaN(value) ? 0 : value);
    setRSI1Sell(value);
  };


  //###TODO
  
  const [SESSIONSBuy, setSESSIONSBuy] = useState(10);
  const handleSESSIONSBuy = (event) => {

    let value = 0; //parseInt(event.target.value, 10);

    if (event.target.value.length > 3) { value = event.target.value.slice(0, 3); }
    else {
      value = parseInt(event.target.value, 10);
    }
    
    //value=parsingValue2D(value);
    if(showBackTest) { setshowBackTest(false)}
    //setMA1Buy(isNaN(value) ? 0 : value);
    setSESSIONSBuy(value);
  };

  const [SESSIONSSell, setSESSIONSSell] = useState(10);
  const handleSESSIONSSell = (event) => {

    let value = 0; //parseInt(event.target.value, 10);

    if (event.target.value.length > 3) { value = event.target.value.slice(0, 3); }
    else {
      value = parseInt(event.target.value, 10);
    }

    //let value = parseInt(event.target.value, 10);
    //value=parsingValue2D(value);

    if(showBackTest) { setshowBackTest(false)}
    //setMA1Buy(isNaN(value) ? 0 : value);
    setSESSIONSSell(value);
  };
  
  const [numberOfMonths, setNumberOfMonths] = useState(24);

  const handleMonthsChange = (event) => {

    setEmail(" ")
            sendAnalytics("btnBTTIME","Desktop",SYMBOL_STOCK);
            setshowPopPup(!showPopPup);

    /*
  
    let value = parseInt(event.target.value, 10);

    // Limitar el valor de numberOfMonths al rango de 1 a 999
    if (isNaN(value)) {
      value = 1;
    } else if (value < 1) {
      value = 1;
    } else if (value > 999) {
      value = 999;
    }

    if(showBackTest) { setshowBackTest(false)}
    setNumberOfMonths(isNaN(value) ? 0 : value);
    */
  };

  const conditionsByIndicatorBUY = {
    '':  ['Condition'],
    'Volume': ['Above average', 'Double the average', 'More than double the average', 'Below average','Less than half the average'],

    'Bollinger Band': ['Bullish cross of the central band', 'Bearish cross of the central band', 'Partial breakout upper band',
    'Partial breakout lower band', 'Full breakout upper band', 'Full breakout lower band'],
    //TEMPORAL '50% compression of the average','25% compression of the average', '100% amplitude of the average','200% amplitude of the average'],

    'SAR': ['Bearish-Bullish reversal','Bullish-Bearish reversal'],
    //TEMPORAL 'Price crosses below SAR','Price crosses above SAR'],

    'MA Crossover': [  'Bullish cross 20-50MA',  'Bearish cross 20-50MA',
     'Bullish CUSTOM cross',  'Bearish CUSTOM cross','Bullish price CUSTOM cross',  'Bearish price CUSTOM cross',
    'Golden Cross: 50-200MA', 'Death Cross: 50-200MA',
    'Bullish price cross 200MA', 'Bearish price cross 200MA', 'Bullish price cross 50MA', 'Bearish price cross 50MA', 'Bullish price cross 20MA', 'Bearish price cross 20MA'],

    'MACD': ['Bullish MACD cross with signal','Bearish MACD cross with signal'],

    //'RSI': ['Lower 75','Lower 70','Lower 60', 'Lower 50','Lower 30','Lower 20','Upper 20','Upper 30','Upper 50','Upper 60','Upper 70', 'Upper 75'],
    'RSI': ['Upper', 'Lower'],

    'ATR': ['Bullish cross 75%','Bullish cross 25%','Bearish cross 75%','Bearish cross 25%','Bullish cross 90%','Bullish cross 10%',
            'Bearish cross 90%', 'Bearish cross 10%'],

    'Stochastic Oscillator': ['Bullish STOCH cross with signal','Bearish STOCH cross with signal'],

    'ForceIndex 1': ['Upper 90% average (200s)','Upper 70% average (200s)', 'Lower 30% average (200s)','Lower 10% average (200s)'],
    
    'ForceIndex 13': [ 'Bearish-Bullish Cross', 'Bullish-Bearish Cross'],
    //TEMPORAL 'Lows (50% average)', 'Highs (150% average)', '10% <= last low', '10% <= last high', 'Minimum bullish turn', 'Maximum bearish turn'],

    'Elder Ray': ['Bearish-Bullish Cross', 'Bullish-Bearish Cross', 'Lows (50% average)','Lows (50% average)', 
    'Highs (150% average)','10% <= last low', '10% <= last high', 'Minimum bullish turn', 'Maximum bearish turn'],
    
  };






  const indicatorOptionsBUY = [
    { label: 'Indicator BUY', value: '', disabled: true },
    //{ label: 'Volume', value: 'Volume' },
    { label: 'Bollinger Band', value: 'Bollinger Band' },
    { label: 'SAR', value: 'SAR' },
    { label: 'MA Crossover', value: 'MA Crossover' },
    { label: 'MACD', value: 'MACD' },
    { label: 'RSI', value: 'RSI' },
    //{ label: 'ATR', value: 'ATR' },
    { label: 'Stochastic Oscillator', value: 'Stochastic Oscillator' },
   // { label: 'ForceIndex 1', value: 'ForceIndex 1' },
    { label: 'ForceIndex 13', value: 'ForceIndex 13' },
   // { label: 'Elder Ray', value: 'Elder Ray' },
  ];

  const indicatorOptionsSELL = [
    { label: 'WITHOUT Trigger SELL', value: '' },
    //{ label: 'Volume', value: 'Volume' },
    { label: 'Bollinger Band', value: 'Bollinger Band' },
    { label: 'SAR', value: 'SAR' },
    { label: 'MA Crossover', value: 'MA Crossover' },
    { label: 'MACD', value: 'MACD' },
    { label: 'RSI', value: 'RSI' },
   // { label: 'ATR', value: 'ATR' },
    { label: 'Stochastic Oscillator', value: 'Stochastic Oscillator' },
   // { label: 'ForceIndex 1', value: 'ForceIndex 1' },
    { label: 'ForceIndex 13', value: 'ForceIndex 13' },
   // { label: 'Elder Ray', value: 'Elder Ray' },
    { label: 'Sessions', value: 'Sessions' },
  ];

  const conditionsByIndicatorSELL = {
    '':  ['Condition'],
    'Volume': ['Above average', 'Double the average', 'More than double the average', 'Below average','Less than half the average'],

    'Bollinger Band': ['Bearish cross of the central band', 'Bullish cross of the central band',  'Partial breakout upper band',
    'Partial breakout lower band', 'Full breakout upper band', 'Full breakout lower band'],
    //TEMPORAL '50% compression of the average','25% compression of the average', '100% amplitude of the average','200% amplitude of the average'],

    'SAR': ['Bullish-Bearish reversal','Bearish-Bullish reversal'], 
    //TEMPORAL 'Price crosses below SAR','Price crosses above SAR'],

    'MA Crossover': [  'Bearish cross 20-50MA', 'Bullish cross 20-50MA', 
     'Bullish CUSTOM cross',  'Bearish CUSTOM cross','Bullish price CUSTOM cross',  'Bearish price CUSTOM cross',
    'Golden Cross: 50-200MA', 'Death Cross: 50-200MA',
    'Bullish price cross 200MA', 'Bearish price cross 200MA', 'Bullish price cross 50MA', 'Bearish price cross 50MA', 'Bullish price cross 20MA', 'Bearish price cross 20MA'],

    
    //'MA Crossover': ['Bullish cross 20-50MA',  'Bearish cross 20-50MA', 'Golden Cross: 50-200MA', 'Death Cross: 50-200MA',
    //'Bullish price cross 200MA', 'Bearish price cross 200MA', 'Bullish price cross 50MA', 'Bearish price cross 50MA',  'Bullish price cross 20MA', 'Bearish price cross 20MA'],

    'MACD': ['Bearish MACD cross with signal', 'Bullish MACD cross with signal'],

   // 'RSI': ['Lower 75','Lower 70','Lower 60', 'Lower 50','Lower 30','Lower 20','Upper 20','Upper 30','Upper 50','Upper 60','Upper 70', 'Upper 75'],
 'RSI': ['Lower','Upper'],


    'ATR': ['Bullish cross 75%','Bullish cross 25%','Bearish cross 75%','Bearish cross 25%','Bullish cross 90%','Bullish cross 10%',
            'Bearish cross 90%', 'Bearish cross 10%'],

    'Stochastic Oscillator': ['Bearish STOCH cross with signal','Bullish STOCH cross with signal'],

    'ForceIndex 1': ['Upper 90% average (200s)','Upper 70% average (200s)', 'Lower 30% average (200s)','Lower 10% average (200s)'],
    
    'ForceIndex 13': [ 'Bullish-Bearish Cross', 'Bearish-Bullish Cross'],
    //TEMPORAL 'Lows (50% average)', 'Highs (150% average)', '10% <= last low', '10% <= last high', 'Minimum bullish turn', 'Maximum bearish turn'],

    'Elder Ray': ['Bearish-Bullish Cross', 'Bullish-Bearish Cross', 'Lows (50% average)','Lows (50% average)', 
    'Highs (150% average)','10% <= last low', '10% <= last high', 'Minimum bullish turn', 'Maximum bearish turn'],

    'Sessions': ['3','5','7','10','12','15','17','20','22','25','27','30','35','40','45','60','90','120'],
  };

 
  // Datos simulados (puedes reemplazarlos con tus propios datos)
  var dataSummary = [
    { numStrategies: 27, 
      daysPerStrategy: 25.6, maxDrawdown: 5.77, maxRunup: 23.67, avgReturnPerStrategy: 6.78, totalReturn: 47.89, winners: 20, losers: 7, ties: 1 },
    // Agrega más datos según sea necesario
  ];

  /*
  const data = [
    // Aquí puedes agregar tus datos ficticios (20 filas) simulando el comportamiento de las acciones de Apple
    // Cada objeto debe tener las propiedades correspondientes a las columnas (Fecha inicio, Nivel inicio, etc.)
    // Ejemplo:
    { fechaInicio: '2023-01-01', nivelInicio: 150, fechaFinal: '2023-11-12', nivelFinal: 170, dias: 10, drawdownMax: 5, rentabilidadDia: 1, rentabilidadTotal: 10 },
    {
      fechaInicio: '2023-01-01',
      nivelInicio: 150,
      fechaFinal: '2023-01-10',
      nivelFinal: 170,
      dias: 10,
      drawdownMax: 5,
      rentabilidadDia: 1.5,
      rentabilidadTotal: 10,
    },
    {
      fechaInicio: '2023-01-02',
      nivelInicio: 160,
      fechaFinal: '2023-01-08',
      nivelFinal: 175,
      dias: 8,
      drawdownMax: 4,
      rentabilidadDia: -0.5,
      rentabilidadTotal: -2,
    },
    // ... más filas de datos ...
    {
      fechaInicio: '2023-01-20',
      nivelInicio: 180,
      fechaFinal: '2023-02-01',
      nivelFinal: 200,
      dias: 12,
      drawdownMax: 6,
      rentabilidadDia: 2,
      rentabilidadTotal: 15,
    },
    {
      fechaInicio: '2023-01-02',
      nivelInicio: 160,
      fechaFinal: '2023-02-08',
      nivelFinal: 175,
      dias: 38,
      drawdownMax: 43,
      rentabilidadDia: -1.3,
      rentabilidadTotal: -12.7,
    },
    { fechaInicio: '2023-01-01', nivelInicio: 150, fechaFinal: '2023-11-12', nivelFinal: 170, dias: 10, drawdownMax: 5, rentabilidadDia: 1, rentabilidadTotal: 10 },
    {
      fechaInicio: '2023-01-02',
      nivelInicio: 160,
      fechaFinal: '2023-01-08',
      nivelFinal: 175,
      dias: 8,
      drawdownMax: 4,
      rentabilidadDia: -0.5,
      rentabilidadTotal: -2,
    },
    // ... más filas de datos ...
    {
      fechaInicio: '2023-01-20',
      nivelInicio: 180,
      fechaFinal: '2023-02-01',
      nivelFinal: 200,
      dias: 12,
      drawdownMax: 6,
      rentabilidadDia: 2,
      rentabilidadTotal: 15,
    },

    { fechaInicio: '2023-01-01', nivelInicio: 150, fechaFinal: '2023-11-12', nivelFinal: 170, dias: 10, drawdownMax: 5, rentabilidadDia: 1, rentabilidadTotal: 10 },
    {
      fechaInicio: '2023-01-01',
      nivelInicio: 150,
      fechaFinal: '2023-01-10',
      nivelFinal: 170,
      dias: 10,
      drawdownMax: 5,
      rentabilidadDia: 1.5,
      rentabilidadTotal: 10,
    },
    {
      fechaInicio: '2023-01-02',
      nivelInicio: 160,
      fechaFinal: '2023-01-08',
      nivelFinal: 175,
      dias: 8,
      drawdownMax: 4,
      rentabilidadDia: -0.5,
      rentabilidadTotal: -2,
    },
    // ... más filas de datos ...
    {
      fechaInicio: '2023-01-20',
      nivelInicio: 180,
      fechaFinal: '2023-02-01',
      nivelFinal: 200,
      dias: 12,
      drawdownMax: 6,
      rentabilidadDia: 2,
      rentabilidadTotal: 15,
    },
    {
      fechaInicio: '2023-01-02',
      nivelInicio: 160,
      fechaFinal: '2023-02-08',
      nivelFinal: 175,
      dias: 38,
      drawdownMax: 43,
      rentabilidadDia: -1.3,
      rentabilidadTotal: -12.7,
    },
    { fechaInicio: '2023-01-01', nivelInicio: 150, fechaFinal: '2023-11-12', nivelFinal: 170, dias: 10, drawdownMax: 5, rentabilidadDia: 1, rentabilidadTotal: 10 },
    {
      fechaInicio: '2023-01-02',
      nivelInicio: 160,
      fechaFinal: '2023-01-08',
      nivelFinal: 175,
      dias: 8,
      drawdownMax: 4,
      rentabilidadDia: -0.5,
      rentabilidadTotal: -2,
    },
    // ... más filas de datos ...
    {
      fechaInicio: '2023-01-20',
      nivelInicio: 180,
      fechaFinal: '2023-02-01',
      nivelFinal: 200,
      dias: 12,
      drawdownMax: 6,
      rentabilidadDia: 2,
      rentabilidadTotal: 15,
    },
    // ... más filas de datos ...
    // ... más filas de datos ...
  ];*/

  
  // Este es un componente funcional que usa el hook useFetchTodoBT
/*function BackTestResult(props) {
  const { todoHistory, todoData, isFetchingHistory, isFetchingData } = useFetchTodoBT(true,"DOCN")//"IAG.MC,EURUSD=X")

  if(!isFetchingData) 
    {
     setshowBackTest(true)
    }
}*/

function calcDataSumary(strat) {

  const strategies=strat;
  //console.log("strategies:"+JSON.stringify(strategies))
  /*if (!Array.isArray(strategies)) {
    throw new Error('Se esperaba un array de estrategias');
  }*/

  const numStrategies = strategies.length;

  if (numStrategies === 0) {
    return {
      numStrategies: 0,
      daysPerStrategy: 0,
      maxDrawdown: 0,
      maxRunup: 0,
      avgReturnPerStrategy: 0,
      totalReturn: 0,
      winners: 0,
      losers: 0,
      ties: 0,
    };
  }

  let totalDays = 0;
  let totalDrawdown = 0;
  let totalRunup = 0;
  let totalReturn = 0;
  let numWinners = 0;
  let numLosers = 0;
  let numTies = 0;

  strategies.forEach((strategy) => {
    totalDays += strategy.dias;
    totalDrawdown = Math.min(totalDrawdown, strategy.drawdownMax);
    totalRunup = Math.max(totalRunup, strategy.runupMax || 0);
    totalReturn += strategy.rentabilidadTotal;

    if (strategy.rentabilidadTotal > 0) {
      numWinners++;
    } else if (strategy.rentabilidadTotal < 0) {
      numLosers++;
    } else {
      numTies++;
    }
  });

  const daysPerStrategy = totalDays / numStrategies;
  const avgReturnPerStrategy = totalReturn / numStrategies;

  return {
    numStrategies: numStrategies,
    daysPerStrategy: daysPerStrategy,
    maxDrawdown: totalDrawdown.toFixed(2),
    maxRunup: totalRunup.toFixed(2),
    avgReturnPerStrategy: avgReturnPerStrategy.toFixed(2),
    totalReturn: totalReturn.toFixed(2),
    winners: numWinners,
    losers: numLosers,
    ties: numTies,
  };
}


// Este es un componente funcional que usa el hook useFetchTodoBT
function BackTestResult(props) {

  var fSHOWselectedItemBUY = SHOWselectedItemBUY;
  var fSHOWselectedConditionBUY = SHOWselectedConditionBUY;
  var fSHOWselectedItemSELL = SHOWselectedItemSELL;
  var fSHOWselectedConditionSELL = SHOWselectedConditionSELL;
  var fSHOWselectedBacktestTIME = SHOWselectedBacktestTIME;

  //Control de errores por si el user no introduce las medias custom
  if(isNaN(SESSIONSBuy) || SESSIONSBuy<1) { setSESSIONSBuy(10); }
  if(isNaN(SESSIONSSell) || SESSIONSSell<1) { setSESSIONSSell(10); }
  if(SESSIONSSell>200) { setSESSIONSSell(200); }

  try {

    if(fSHOWselectedItemBUY=="Sessions") {
      fSHOWselectedConditionBUY=""+SESSIONSBuy;
    }

    if(fSHOWselectedItemSELL=="Sessions") {
      fSHOWselectedConditionSELL=""+SESSIONSSell;
      SHOWselectedConditionSELL=""+SESSIONSSell;
    }
    else {
    if(fSHOWselectedItemSELL.length<1) { fSHOWselectedItemSELL="Sessions"; }
    if(fSHOWselectedConditionSELL.length<1) { fSHOWselectedConditionSELL="10"; }
    }
  }
  catch(Exx) {}

  /*
  console.log("fSHOWselectedItemBUY:"+fSHOWselectedItemBUY)
  console.log("fSHOWselectedConditionBUY:"+fSHOWselectedConditionBUY)
  console.log("MA1Buy:"+MA1Buy)
  console.log("MA2Buy:"+MA2Buy)
  */

  //Control de errores por si el user no introduce las medias custom
  

  if(isNaN(RSI1Buy) || RSI1Buy<1) { setRSI1Buy(60); }
  if(isNaN(RSI1Sell) || RSI1Sell<1) { setRSI1Sell(60); }

  if(isNaN(MA1Buy) || MA1Buy<1) { setMA1Buy(1); }
  if(isNaN(MA2Buy) || MA2Buy<1) { setMA2Buy(5); }
  if(isNaN(MA1Sell) || MA1Sell<1) { setMA1Sell(1); }
  if(isNaN(MA2Sell) || MA2Sell<1) { setMA2Sell(5); }

  if(isNaN(sesis1Buy) || sesis1Buy<1) { setsesis1Buy(1); }
  if(isNaN(sesis1Sell) || sesis1Sell<1) { setsesis1Sell(1); }
  
  
  //########## BUY ###########//
  if(fSHOWselectedConditionBUY=="Upper") {
    fSHOWselectedConditionBUY="Upper "+RSI1Buy;
  }
  if(fSHOWselectedConditionBUY=="Lower") {
    fSHOWselectedConditionBUY="Lower "+RSI1Buy;
  }

  if(fSHOWselectedConditionBUY=="Bullish CUSTOM cross") {
    fSHOWselectedConditionBUY="Bullish cross "+MA1Buy+"-"+MA2Buy+"MA";
  }
  else if(fSHOWselectedConditionBUY=="Bullish price CUSTOM cross") {
    fSHOWselectedConditionBUY="Bullish price cross "+MA1Buy+"MA";
  }
  else if(fSHOWselectedConditionBUY=="Bearish CUSTOM cross") {
    fSHOWselectedConditionBUY="Bearish cross "+MA1Buy+"-"+MA2Buy+"MA";
  }
  else if(fSHOWselectedConditionBUY=="Bearish price CUSTOM cross") {
    fSHOWselectedConditionBUY="Bearish price cross "+MA1Buy+"MA";
  }
  
  //########## SELL ###########//
  if(fSHOWselectedConditionSELL=="Upper") {
    fSHOWselectedConditionSELL="Upper "+RSI1Sell;
  }
  if(fSHOWselectedConditionSELL=="Lower") {
    fSHOWselectedConditionSELL="Lower "+RSI1Sell;
  }

  if(fSHOWselectedConditionSELL=="Bearish CUSTOM cross") {
    fSHOWselectedConditionSELL="Bearish cross "+MA1Sell+"-"+MA2Sell+"MA";
  }
  else if(fSHOWselectedConditionSELL=="Bearish price CUSTOM cross") {
    fSHOWselectedConditionSELL="Bearish price cross "+MA1Sell+"MA";
  }
  else if(fSHOWselectedConditionSELL=="Bullish CUSTOM cross") {
    fSHOWselectedConditionSELL="Bullish cross "+MA1Sell+"-"+MA2Sell+"MA";
  }
  else if(fSHOWselectedConditionSELL=="Bullish price CUSTOM cross") {
    fSHOWselectedConditionSELL="Bullish price cross "+MA1Sell+"MA";
  }

  /*console.log("fSHOWselectedItemBUY:"+fSHOWselectedItemBUY);
  console.log("fSHOWselectedConditionBUY:"+fSHOWselectedConditionBUY);
  console.log("fSHOWselectedItemSELL:"+fSHOWselectedItemSELL);
  console.log("fSHOWselectedConditionSELL:"+fSHOWselectedConditionSELL);
  console.log("fSHOWselectedBacktestTIME:"+fSHOWselectedBacktestTIME);
  console.log("sesis1Sell:"+sesis1Sell)*/

  const { todoHistory, todoData, isFetchingHistory, isFetchingData } = 
    useFetchTodoBT(SYMBOL_STOCK,
    fSHOWselectedItemBUY,
    fSHOWselectedConditionBUY,
    fSHOWselectedItemSELL,
    fSHOWselectedConditionSELL,
    fSHOWselectedBacktestTIME,
    sesis1Buy,
    sesis1Sell
    );

  if(!isFetchingData) 
  {
    
    //Calculamos dataSumary
    dataSummary=[calcDataSumary(todoData)];
    //console.log("dataSummary:"+JSON.stringify(dataSummary));





    setIsLoadingBT(false);

    if(doScrollMobile) {  setScrollMobile(false); scrollToBottom(1400,500); }
    else if(doScrollDesktop) { setScrollDesktop(false); scrollToBottom(800,500); }
     

    
    return (
<GridItem colStart={COL_START} colEnd={COL_END} rounded={10} justifyItems="center" 
backgroundColor="#FFFFFF">

<VStack width="100%" flexDirection="column" justifyContent="center" p={4} mt={0} mb={10}>

<Text textAlign="center"  fontSize="22"  p={0} pl={4} pr={4} pb={6}  textColor="black" fontFamily="Tahoma"
        background="white" fontWeight="semibold" > 
        {TICKER_STOCK} backtest trading data sheet
         </Text>




<VStack>

{(1==2)&&<Text><b>Trigger buy:</b> Indicator {SHOWselectedItemBUY.length > 1 ? SHOWselectedItemBUY : ''} with condition {SHOWselectedConditionBUY} <br></br></Text>}
{(1==2)&&<Text><b>Trigger sell:</b> Indicator {SHOWselectedItemSELL.length > 1 ? SHOWselectedItemSELL : ''} with condition {SHOWselectedConditionSELL}<br></br><br></br></Text>}


<Text textAlign="center"  fontSize="16"   textColor="black" fontFamily="Tahoma"
        background="white" fontWeight="normal" > 



        {SHOWselectedItemBUY.length > 1 && (
        <div>
         <b>Trigger buy: </b> {SHOWselectedItemBUY.length > 1 ? SHOWselectedItemBUY : ''} with {SHOWselectedConditionBUY} condition<br></br>
         </div>
         )}

        {SHOWselectedItemSELL.length > 1 && (
        <div>
        <b>Trigger sell: </b> {SHOWselectedItemSELL.length > 1 ? SHOWselectedItemSELL : ''} with {SHOWselectedConditionSELL} condition<br></br><br></br>
        </div>
         )}
         {SHOWselectedItemSELL.length < 1 && (
        <div>
       In the absence of a sell trigger, standard duration of 10 sessions will be established for each strategy.
         </div>
         )}
         

        <b>Backtest TIME:</b> {SHOWselectedBacktestTIME >= 0 ? SHOWselectedBacktestTIME : ''} months
         </Text>
</VStack>

<HStack pt={6}>
        <Box textAlign="center" marginRight="10" backgroundColor="#FAFAFA" p={4} pt={8}
        _hover={{
          bg: 'gray.100',
          cursor: 'pointer', // Muestra el cursor como enlace al pasar el ratón sobre el cuadro
        }}
        onClick= {() =>
          {  
            setEmail(" ")
            sendAnalytics("btnADDst","Desktop",SYMBOL_STOCK);
            setshowPopPup(!showPopPup);
          }
          }>
          <IconButton
            colorScheme="black"
            aria-label="Add"
            
            icon={<Image src={`${process.env.PUBLIC_URL}/assets/addIcon.png`} boxSize="3.2rem" alt="Add" />}
            bg="black"
          />
          <Text mt="3" fontWeight={"semibold"}>Add at your library</Text>
        </Box>
        <Box textAlign="center" marginLeft="10" backgroundColor="#FAFAFA" p={4} pt={8}
        _hover={{
          bg: 'gray.100',
          cursor: 'pointer', // Muestra el cursor como enlace al pasar el ratón sobre el cuadro
        }}
        onClick= {() =>
          {  
            setEmail(" ")
            sendAnalytics("btnALARMst","Desktop",SYMBOL_STOCK);
            setshowPopPup(!showPopPup);
          }
          }>
          <IconButton
            colorScheme="black"
            aria-label="Alarm"
            
            icon={<Image src={`${process.env.PUBLIC_URL}/assets/alarmIcon.png`}  boxSize="3.2rem" alt="Alarm" />}
            bg="black"
          />
         <Text mt="3" textColor="black" fontWeight={"semibold"}>Set a trigger buy alarm</Text>
        </Box>
      </HStack>
    


<VStack pt={10} pb={10}>
<TableSummary></TableSummary>
</VStack>

<TableExample dataBT={todoData}>
</TableExample>

</VStack>
</GridItem>)


}
else {
  /*return (
    // Aquí puedes mostrar un indicador de carga
  )*/
}
}

function scrollToBottom(distance,duration) {
  // Obten la posición actual del scroll
  const currentPosition = window.scrollY;

  // Define la posición a la que quieres hacer scroll (puede ser hacia abajo o hacia arriba)
  //const targetPosition = document.body.scrollHeight - window.innerHeight;

  // Calcula la distancia que se moverá en cada cuadro de animación (frame)
  //const distance = targetPosition - currentPosition;

  // Define la duración total de la animación en milisegundos
  //const duration = 1000; // por ejemplo, 1000 milisegundos (1 segundo)

  // Obtiene el tiempo actual
  const startTime = performance.now();

  // Crea la función de animación
  function animateScroll(time) {
    // Calcula el progreso de la animación en base al tiempo transcurrido
    const progress = (time - startTime) / duration;

    // Calcula la nueva posición del scroll
    const newPosition = currentPosition + distance * progress;

    // Realiza el scroll a la nueva posición
    window.scrollTo(0, newPosition);

    // Verifica si la animación debe continuar
    if (progress < 1) {
      // Si no ha alcanzado el final, sigue animando
      requestAnimationFrame(animateScroll);
    }
  }

  // Inicia la animación
  requestAnimationFrame(animateScroll);
}

  function DoBackTest() {


    
    //console.log("selectedItemSELL:"+selectedItemSELL)
    //console.log("selectedConditionSELL:"+selectedConditionSELL)
    SHOWselectedItemBUY="";
    SHOWselectedConditionBUY="";
    SHOWselectedItemSELL="";
    SHOWselectedConditionSELL="";
    SHOWselectedBacktestTIME=0;

    
    if(selectedItemBUY.length>1) { SHOWselectedItemBUY=selectedItemBUY; }

    if(selectedConditionBUY.length>1) { SHOWselectedConditionBUY=selectedConditionBUY; }
    else if (selectedItemBUY.length>1) {
      //setSelectedConditionBUY(conditionsByIndicatorBUY[selectedItemBUY][0]);
      //SHOWselectedItemBUY=selectedConditionBUY; 
      SHOWselectedConditionBUY=conditionsByIndicatorBUY[selectedItemBUY][0];
    }

    if(selectedItemSELL.length>1) { SHOWselectedItemSELL=selectedItemSELL; }

    if(selectedConditionSELL.length>1) { SHOWselectedConditionSELL=selectedConditionSELL; }
    else if (selectedItemSELL.length>1) {
      SHOWselectedConditionSELL=conditionsByIndicatorSELL[selectedItemSELL][0];
    }
    
    
    if(numberOfMonths>=0) { SHOWselectedBacktestTIME=numberOfMonths; }
  
    //console.log("SHOWselectedConditionSELL:"+SHOWselectedConditionSELL);

    //Filtrem els triggers que No estan implementats i mostrem missatge de showpopup 
    //Temporal, treurem els indicadors o funcionalitats que NO estiguin implementades
  

    // CONDICIONALS NO IMPLEMENTATS
    if((SHOWselectedConditionBUY=="Price crosses below SAR" || SHOWselectedConditionBUY=="Price crosses above SAR") ||   //### SAR #####/
      (SHOWselectedConditionSELL=="Price crosses below SAR" || SHOWselectedConditionSELL=="Price crosses above SAR"))  //### SAR #####/
    {
      setEmail(" ")
              //setshowPopPup(true);
     
              //this.setState({ ShowPopNewsLogin: true});
              sendAnalytics("btnNOAVAL","Desktop",SHOWselectedItemBUY+"_"+SHOWselectedItemSELL);
              setshowPopPup(!showPopPup);
              setIsLoadingBT(false);
              return;
      }

      //INDICADORS NO IMPLEMENTATS
      if(
      (SHOWselectedItemBUY=="ForceIndex 1" || SHOWselectedItemSELL=="ForceIndex 1") ||
      //(SHOWselectedItemBUY=="Stochastic Oscillator" || SHOWselectedItemSELL=="Stochastic Oscillator") ||
      //(SHOWselectedItemBUY=="MACD" || SHOWselectedItemSELL=="MACD") ||
      //(SHOWselectedItemBUY=="RSI" || SHOWselectedItemSELL=="RSI") ||
      (SHOWselectedItemBUY=="Volume" || SHOWselectedItemSELL=="Volume") ||
      (SHOWselectedItemBUY=="ATR" || SHOWselectedItemSELL=="ATR"))
    {
      setEmail(" ")
              //setshowPopPup(true);
     
              //this.setState({ ShowPopNewsLogin: true});
              sendAnalytics("btnNOAVAL","Desktop",SHOWselectedItemBUY+"_"+SHOWselectedItemSELL);
              setshowPopPup(!showPopPup);
              setIsLoadingBT(false);
              return;
      }

      

    //BackTestResult();
  setshowBackTest(true);

    //Do backtest on server
    /*const { todoHistory, todoData, isFetchingHistory, isFetchingData } = useFetchTodoBT(true,"DOCN")//"IAG.MC,EURUSD=X")

    if(!isFetchingData) 
    {
     setshowBackTest(true)
    }*/
  } 

  const TableSummary = () => {

    //if(!isFetchingTopTraders) 
  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  {
    return (
      <VStack>
      <Container maxW="md" my={{ base: 0, md: 0 }}>
      <Table variant="simple">
      
        <Tr>
          <Th textAlign="center" fontSize={14}>Strategies</Th>
          <Th textAlign="center" fontSize={14}>Winners</Th>
          <Th textAlign="center" fontSize={14}>Losers</Th>
        </Tr>
    
      
        {dataSummary.map((row, index) => (
          <Tr key={index}>
            <Td textAlign="center">{row.numStrategies}</Td>
            <Td textAlign="center">{row.winners}</Td>
            <Td textAlign="center">{row.losers}</Td>
                    </Tr>
        ))}
      
    </Table>
    <Table variant="simple">
      
      <Tr>
      <Th textAlign="center" fontSize={14}>Avg Return/Strat</Th>
            <Th textAlign="center" fontSize={14}>Total Return</Th>
            <Th textAlign="center" fontSize={14}>Efficiency</Th>
      </Tr>
  
    
      {dataSummary.map((row, index) => (
        <Tr key={index}>
           <Td textAlign="center" >{row.avgReturnPerStrategy}%</Td>
              <Td textAlign="center" >{row.totalReturn}%</Td>
              <Td>
                <Flex direction="column" alignItems="center">
                  <CircularProgress
                    value={(row.winners / row.numStrategies) * 100}
                    color="green.500"
                    size="70px"
                    thickness="12px"
                    mb={-2}
                    mt={-2}
                  >
                  <CircularProgressLabel>{Math.round((row.winners / row.numStrategies) * 100)}%</CircularProgressLabel>
                  </CircularProgress>
                </Flex>
              </Td>
                  </Tr>
      ))}
    
  </Table>
    </Container>
    </VStack>
    );
  }
  else {
    return (
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="center" fontSize={14}>Strategies</Th>
            <Th textAlign="center" fontSize={14}>Winners</Th>
            <Th textAlign="center" fontSize={14}>Losers</Th>
            <Th textAlign="center" fontSize={14}>Avg Days/Strat</Th>
            {1==2&&<Th textAlign="center" fontSize={14}>Max Drawdown</Th>}
           {1==2&&<Th textAlign="center" fontSize={14}>Max Runup</Th>}
            <Th textAlign="center" fontSize={14}>Avg Return/Strat</Th>
            <Th textAlign="center" fontSize={14}>Total Ret</Th>
            <Th textAlign="center" fontSize={14}>Efficiency</Th>
          </Tr>
        </Thead>
        <Tbody>
          {dataSummary.map((row, index) => (
            <Tr key={index}>
              <Td textAlign="center">{row.numStrategies}</Td>
              <Td textAlign="center">{row.winners}</Td>
              <Td textAlign="center">{row.losers}</Td>
              <Td textAlign="center">{row.daysPerStrategy.toFixed(2)}</Td>
              {1==2&&<Td textAlign="center">{row.maxDrawdown}%</Td>}
              {1==2&&<Td textAlign="center">{row.maxRunup}%</Td>}
              <Td textAlign="center" >{row.avgReturnPerStrategy}%</Td>
              <Td textAlign="center" >{row.totalReturn}%</Td>
              <Td>
                <Flex direction="column" alignItems="center">
                  <CircularProgress
                    value={(row.winners / row.numStrategies) * 100}
                    color="green.500"
                    size="70px"
                    thickness="12px"
                    mb={-2}
                    mt={-2}
                  >
                  <CircularProgressLabel>{Math.round((row.winners / row.numStrategies) * 100)}%</CircularProgressLabel>
                  </CircularProgress>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      );
    }
  };

  const TableExample = (dataBT) => {

    const dataTrack=dataBT.dataBT;

    //console.log("~~~~dataTRACK:"+JSON.stringify(dataTrack))
    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    {
      return (
        <VStack>
        <Container maxW="md" my={{ base: 0, md: 8 }}>
        <Table variant="simple" > 
        <Thead>
          <Tr>
            <Th textAlign="center" fontSize={14} >Start date</Th>
            <Th textAlign="center" fontSize={14}>Start price</Th>
            <Th textAlign="center" fontSize={14}>Closing date</Th>
            <Th textAlign="center" fontSize={14}>Closing price</Th>
            <Th textAlign="center" fontSize={14}>Days</Th>
            {1==2&&<Th textAlign="center" fontSize={14}>Max drawdown</Th>}
            <Th textAlign="center" fontSize={14}>Daily return</Th>
            <Th textAlign="center" fontSize={14}>Total return 📈💰</Th>
          </Tr>
        </Thead>
        <Tbody>
          {dataTrack.map((row, index) => (
            <Tr key={index} >
              <Td textAlign="center">{row.fechaInicio}</Td>
              <Td textAlign="center">{row.nivelInicio}{moneda}</Td>
              <Td textAlign="center">{row.fechaFinal}</Td>
              <Td textAlign="center">{row.nivelFinal}{moneda}</Td>
              <Td textAlign="center" >{row.dias}</Td>
              {1==2&&<Td textAlign="center">{row.drawdownMax.toFixed(2)}%</Td>}
              <Td textAlign="center" fontWeight="bold" color={row.rentabilidadDia >= 0 ? 'green.500' : 'red.600'}>{row.rentabilidadDia.toFixed(2)}%</Td>
              <Td textAlign="center" fontWeight="bold" color="white" bg={row.rentabilidadTotal >= 0 ?  'green.500' : 'red.600'}>{row.rentabilidadTotal.toFixed(2)}%</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
          </Container>
          </VStack>
          );
          }
          else {
    return (
      
       <Table variant="simple" > 
        <Thead>
          <Tr>
            <Th textAlign="center" fontSize={14} >Start date</Th>
            <Th textAlign="center" fontSize={14}>Start price</Th>
            <Th textAlign="center" fontSize={14}>Closing date</Th>
            <Th textAlign="center" fontSize={14}>Closing price</Th>
            <Th textAlign="center" fontSize={14}>Days</Th>
            <Th textAlign="center" fontSize={14}>Max drawdown</Th>
            <Th textAlign="center" fontSize={14}>Daily return</Th>
            <Th textAlign="center" fontSize={14}>Total return 📈💰</Th>
          </Tr>
        </Thead>
        <Tbody>
          {dataTrack.map((row, index) => (
            <Tr key={index} >
              <Td textAlign="center">{row.fechaInicio}</Td>
              <Td textAlign="center">{row.nivelInicio}{moneda}</Td>
              <Td textAlign="center">{row.fechaFinal}</Td>
              <Td textAlign="center">{row.nivelFinal}{moneda}</Td>
              <Td textAlign="center" >{row.dias}</Td>
              <Td textAlign="center">{row.drawdownMax.toFixed(2)}%</Td>
              <Td textAlign="center" fontWeight="bold" color={row.rentabilidadDia >= 0 ? 'green.500' : 'red.600'}>{row.rentabilidadDia.toFixed(2)}%</Td>
              <Td textAlign="center" fontWeight="bold" color="white" bg={row.rentabilidadTotal >= 0 ?  'green.500' : 'red.600'}>{row.rentabilidadTotal.toFixed(2)}%</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
   }
  };

  var FITXA_DESK=false;
  var COL_START=3;
  var COL_END=13;
  var TEXT_NEWS= "Do you want to know which assets have an interesting technical scenario in the short/medium term? <br />Subscribe to our newsletter, <b>it's free!</b>";

  var P_SIMPLE_GRID=2;
  var P_VSTACK=5;
  var PT_VSTACK=8;
  var PB_VSTACK=10;

  


  try {
    if(fitxaDesk==1) {
      FITXA_DESK=true;
      COL_START=1;
      COL_END=15;
      P_SIMPLE_GRID=0;

      P_VSTACK=0;
      PT_VSTACK=5;
      PB_VSTACK=8;


      
    }
  }
  catch(Ecc) {}
  
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedCriteri, setSelectedCriteri] = useState(null);

  const [showSearch,setShowSearch] = useState(false);
  const [doSearch,setDoSearch] = useState(false);
  const [doRefresh,setDoRefresh] = useState(false);

  
  const [showThanksSubs,setshowThanksSubs] = useState(false);
  const [showErrorSubs,setshowErrorSubs] = useState(false);
  const [email,setEmail] = useState("");

  const [value, setValue] = useState('')
  const handleChange = (event) => setValue(event.target.value)


  var templateParams = {
    email_subscriber: email,
};


  var RANDOM_ID=Math.floor(Math.random() * 99999);
  var BUTTON_SUBS_ID="buttonSubs"+RANDOM_ID;

  const sendAnalytics = (event,device,message) => {

    if(event=="btnRunBT") {
     var dataInfo=device+"_"+message+"_"+selectedItemBUY+"_"+selectedConditionBUY+"_"+selectedItemSELL+"_"+selectedConditionSELL;
     var minInfo=device;

     eventBus.dispatch(event, { data: dataInfo  });
     eventBus.dispatch(event, { data: minInfo  });
    }
    //else if(event=="btnFINDst") 
    else
    {
      //btnFINDst
      var dataInfo=device+"_"+message;
      var minInfo=device;
      eventBus.dispatch(event, { data: dataInfo  });
      eventBus.dispatch(event, { data: minInfo  });
    }
  }

  const [show, setShow] = useState(false);

  //if(!isFetchingTopTraders) 
  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  {
    return(
      
      <Box backgroundColor="#FFFFFF" rounded={10} width= "100%" mt={0} mb={0}>     

      {showPopPup && <Box zIndex={3} background="red"position="absolute"
      >
              <div className='popup'>
              <div className='popup_inner_mobile'>
              <VStack width="100%" flexDirection="column" justifyContent="center" p={5} pt={2} pb={20} alignContent="center">
              <Text textAlign="center" fontSize="24" textColor="black" fontFamily="Tahoma" fontWeight="semibold" marginBottom={2}> 
              {i18n.t("op_no_dispo")}
                </Text>
                <Text textAlign="center" mt={10} fontSize="16" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
                {i18n.t("quieres_activa")}  <strong>{i18n.t("subs_news")}</strong>
                </Text>
              
                <Text textAlign="center" mt={5} fontSize="16" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
                {i18n.t("activ_func_aviso")}
                </Text>
      
                <div className="formField">
                  <input
                    type="email"
                    id="email"
                    className="formFieldInput"
                    placeholder={i18n.t("intro_email")}
                    name="email"
                    value={email}
                    required
                    onChange={e => setEmail(e.target.value)}
                    //value={this.state.email}
                    //onChange={this.handleChange}
        
        
                  />
                </div>
      
                <Flex marginTop={15}>
                <button className="formSignInButton" marginTop={5} _hover={{ cursor: "pointer" }} 
              onClick= {(e) =>
               {
                //sendEmail(e)
                {
                  e.preventDefault();
              
                  try {
                  if(email.length>10 && email.includes("@") && email.includes(".")) {
              
                   var templateParams = {
                      email_subscriber: email,
                  };
                  
              
                   templateParams.email_subscriber=email;
                   emailjs.send(glovar.REF_EMAIL_SERVICE, glovar.REF_EMAIL_TEMPLATE, templateParams, glovar.REF_EMAIL_KEY)
                   .then(function(response) {
                     //console.log('SUCCESS!', response.status, response.text);
                   }, function(error) {
                     //console.log('FAILED...', error);
                   });
                  }
                  else {
                    
                  }
              
                  }
                  catch(Exxx) {}
                };
                //console.log("buscar!!")
                setshowPopPup(false)
               }
               }
               style={{ paddingLeft:20, paddingRight:20, marginTop:"10px", fontWeight:"semibold", textAlign:"center", fontSize:"large", marginLeft:5}}>
               {i18n.t("subs_mayus")}</button>
               </Flex>   
               <Text  as='u' textAlign="center" paddingTop="10px" fontSize="12" textColor="black" fontFamily="Tahoma" 
               _hover={{ cursor: "pointer" }} fontWeight="normal" 
               onClick= {() =>
                {
       
                 //console.log("buscar!!")
                 setshowPopPup(false)
                }
                }> 
                {i18n.t("no_gracias")}
                </Text>
      
               </VStack>
              </div>
            </div>
            </Box>}
      
      
          <SimpleGrid   columns={14} gap={1} p={P_SIMPLE_GRID} m={0} mt={0} mb={0} 
          justifySelf="center" rounded={10} height="maxHeight" backgroundColor="#FFFFFF">
      
         
             <GridItem colStart={COL_START} colEnd={COL_END} rounded={10} justifyItems="center" 
              backgroundColor="#FFFFFF">
            
             <VStack width="100%" flexDirection="column" justifyContent="center" mt={3}>
             
             <Text textAlign="center"  fontSize="22"  p={0} pl={4} pr={4} textColor="black" fontFamily="Tahoma"
                background="white" fontWeight="semibold" > 
                 Improve your trading on<br />{TICKER_STOCK}<br />
                Test your strategy here!
                 </Text>
      
                 <Text textAlign="center"  fontSize="16"  p={2} ml={4} mr={4} width="95%" textColor="black" fontFamily="Tahoma"
          background="#EEEEEE" fontWeight="normal"
          _hover={{
            bg: 'gray.100',
            cursor: 'pointer', // Muestra el cursor como enlace al pasar el ratón sobre el cuadro
          }}
          onClick= {() =>
            {  
              /*
              setSelectedItemBUY("Bollinger Band");
              setSelectedConditionBUY("Bullish cross of the central band");
              setSelectedItemSELL("Sessions");
              setSESSIONSSell(12);
              //setSelectedConditionSELL(14);
              //setNumberOfMonths(24);
              SHOWselectedItemBUY="Bollinger Band";
              SHOWselectedConditionBUY="Bullish cross of the central band";
              SHOWselectedItemSELL="Sessions";
              SHOWselectedConditionSELL="12";
              SHOWselectedBacktestTIME=24;*/

              try {
              setSelectedItemBUY(element.propbtItemBUY);
              setSelectedConditionBUY(element.propbtConditionBUY);
              setSelectedItemSELL(element.propbtItemSELL);
              setSESSIONSSell(element.propbtConditionSELL);
              
              setsesis1Sell(element.sessisSELL); 
              setsesis1Buy(element.sessisBUY); 
              
              //setSelectedConditionSELL(14);
              //setNumberOfMonths(24);
              SHOWselectedItemBUY=element.propbtItemBUY;
              SHOWselectedConditionBUY=element.propbtConditionBUY;
              SHOWselectedItemSELL=element.propbtItemSELL;
              SHOWselectedConditionSELL=element.propbtConditionSELL;
              SHOWselectedBacktestTIME=element.propbtBacktestTIME;
              }
              catch(Exx) {
                setSelectedItemBUY("Bollinger Band");
                setSelectedConditionBUY("Bullish cross of the central band");
                setSelectedItemSELL("Sessions");
                setSESSIONSSell(12);
                //setSelectedConditionSELL(14);
                //setNumberOfMonths(24);
                SHOWselectedItemBUY="Bollinger Band";
                SHOWselectedConditionBUY="Bullish cross of the central band";
                SHOWselectedItemSELL="Sessions";
                SHOWselectedConditionSELL="12";
                SHOWselectedBacktestTIME=24;

                element.propbtItemBUY="Bollinger Band";
                  element.propbtConditionBUY="Bullish cross of the central band";
                  element.propbtItemSELL="Sessions";
                  element.propbtConditionSELL="12";
                  element.propbtBacktestTIME=24;
              }

              sendAnalytics("btnPropBT","Mobile",SYMBOL_STOCK);

              setshowBackTest(true);
              //window.scrollBy(0, 800);
              setScrollMobile(true);
              //scrollToBottom(1400,500);

              //DoBackTest();
              //setEmail(" ")
              //sendAnalytics("btnADDst","Desktop",SYMBOL_STOCK);
              //setshowPopPup(!showPopPup);
            }
            } > 
            <span style={{ textDecoration: 'underline' }}>EX: {element.propbtItemBUY} with {element.propbtConditionBUY} condition with a {element.propbtConditionSELL}-{element.propbtItemSELL} sell trigger. <b>Efficiency: {element.propbtEfficiency}%</b></span>
         
           </Text>

               </VStack>


            

      {!show&&<button className="runbacktest" id={BUTTON_SUBS_ID} marginTop={5} _hover={{ cursor: "pointer" }} 
                onClick={() => setShow(!show)}
                 style={{ paddingLeft:40, paddingRight:40, marginTop:20,  marginBottom:20,  fontWeight:"semibold", 
                 textAlign:"center", fontSize:"medium", marginLeft:5}}>
                {(1==2)/*isLoadingBT*/ ? (<CircularProgress size="44px"
                    thickness="10px" isIndeterminate color='white' mt="-6px" />) : ('Find the BEST strategy for '+SYMBOL_STOCK)}
                 </button>}

      <div className={`content ${show ? "show" : ""}`}>
               <VStack width="100%" flexDirection="column" justifyContent="center" p={P_VSTACK} pt={PT_VSTACK} pb={PB_VSTACK} alignContent="center">
            
                  
               <Table /*variant="simple"*/ variant="unstyled" mb={2}>
  <Thead>
    <Tr>
      <Th colSpan={1} fontSize={14} width="100%" borderTopWidth="0px">Trigger BUY</Th>
    </Tr>
  </Thead>
  <Tbody>
    <Tr>
      <Td borderWidth="0px">
        <Select value={selectedItemBUY} onChange={handleSelectChangeBUY}>
          {indicatorOptionsBUY.map((option, index) => (
            <option
              key={index}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </Select>
      </Td>
    </Tr>
    <Tr>
      <Td>
        {(
          <Select
            value={selectedConditionBUY}
            onChange={handleSelectChangeConditionBUY}
          >
            {conditionsByIndicatorBUY[selectedItemBUY].map(
              (condition, index) => (
                <option key={index} value={condition}>
                  {condition}
                </option>
              )
            )}
          </Select>
        )}
      </Td>      
    </Tr>

    <Tr>    
      
            {(selectedItemBUY=="MA Crossover" && selectedConditionBUY.includes("CUSTOM"))&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={MA1Buy}
                    onChange={handleMA1Buy}
                    maxLength={3} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="MA1" />
                </InputGroup>
                { selectedItemBUY=="MA Crossover" && selectedConditionBUY.includes("CUSTOM") && !selectedConditionBUY.includes("price CUSTOM")&&
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={MA2Buy}
                    onChange={handleMA2Buy}
                    maxLength={3} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="MA2" />
                  </InputGroup>}
              </Td>}

              {(selectedItemBUY=="RSI")&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={RSI1Buy}
                    onChange={handleRSI1Buy}
                    maxlength="2" // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="RSI B" />
                </InputGroup>
              </Td>}

          

              </Tr>

              
              {(/*selectedItemBUY!="MA Crossover" &&*/ (selectedItemBUY.length>1))&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={sesis1Buy}
                    onChange={handleSESSIS1Buy}
                    maxLength={2} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="40%" children="sessions" />
                </InputGroup>
                <Text ml={1} width="70%" fontSize="14" mt={1} textColor="black" fontFamily="Tahoma" fontWeight="normal"> 
                condition BUY triggered
                </Text>
              </Td>}

    <Tr>
      <Th colSpan={1} fontSize={14} width="100%" borderTopWidth="1px">Trigger SELL</Th>
    </Tr>
    <Tr>
      <Td borderWidth="0px">
        <Select value={selectedItemSELL} onChange={handleSelectChangeSELL}>
          {indicatorOptionsSELL.map((option, index) => (
            <option
              key={index}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </Select>
      </Td>
    </Tr>
    <Tr>
    {selectedItemSELL!="Sessions"&&<Td>
        {(
          <Select
            value={selectedConditionSELL}
            onChange={handleSelectChangeConditionSELL}
          >
            {conditionsByIndicatorSELL[selectedItemSELL].map(
              (condition, index) => (
                <option key={index} value={condition}>
                  {condition}
                </option>
              )
            )}
          </Select>
        )}
      </Td>}
      {selectedItemSELL=="Sessions"&&<Td>
              <InputGroup>
                  <Input
                  width="50%"
                    type="number"
                    value={SESSIONSSell}
                    onChange={handleSESSIONSSell}
                    maxlength="3" // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="50%" children="sessions" />
                </InputGroup>
              </Td>}
    </Tr>

    <Tr>    
              



              {(1==2 && selectedItemBUY=="MA Crossover" && selectedConditionBUY.includes("CUSTOM") && !selectedConditionBUY.includes("price CUSTOM"))&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={MA2Buy}
                    onChange={handleMA2Buy}
                    maxLength={3} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="MA2" />
                </InputGroup>
              </Td>}
            
              {(selectedItemSELL=="MA Crossover" && selectedConditionSELL.includes("CUSTOM"))&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={MA1Sell}
                    onChange={handleMA1Sell}
                    maxLength={3} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="MA1" />
                </InputGroup>
              
              {(selectedItemSELL=="MA Crossover" && selectedConditionSELL.includes("CUSTOM") && !selectedConditionSELL.includes("price CUSTOM"))&&
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={MA2Sell}
                    onChange={handleMA2Sell}
                    maxLength={3} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="MA2" />
                </InputGroup>}
              </Td>}

              {(selectedItemSELL=="RSI")&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={RSI1Sell}
                    onChange={handleRSI1Sell}
                    maxLength={2} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="RSI S" />
                </InputGroup>
              </Td>}

            </Tr>
            {(/*selectedItemSELL!="MA Crossover" &&*/ selectedItemSELL!="WHITOUT Trigger SELL" && selectedItemSELL!="Sessions"
               && (selectedItemSELL.length>1))&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={sesis1Sell}
                    onChange={handleSESSIS1Sell}
                    maxLength={2} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="40%" children="sessions" />
                </InputGroup>
                <Text ml={1} width="70%" fontSize="14" mt={1} textColor="black" fontFamily="Tahoma" fontWeight="normal"> 
                condition SELL triggered
                </Text>
              </Td>}
            
    <Tr>
      <Th colSpan={1} fontSize={14} width="100%" borderTopWidth="1px">BACKTEST time</Th>
    </Tr>
    <Tr>
      <Td>
        <InputGroup>
          <Input
            width="50%"
            type="number"
            value={numberOfMonths}
            onChange={handleMonthsChange}
            maxLength={3} // Limita la longitud del número a 3 dígitos
            pattern="\d*" // Permite solo caracteres numéricos
          />
          <InputRightAddon width="50%" children="months" />
        </InputGroup>
      </Td>
    </Tr>

   

  </Tbody>
</Table>
           
      
                  
                  <button className="runbacktest" id={BUTTON_SUBS_ID} marginTop={5} _hover={{ cursor: "pointer" }} 
                onClick= {(e) =>
                 {
                  //document.getElementById(BUTTON_SUBS_ID).disabled = true;
                  //document.getElementById(BUTTON_SUBS_ID).style.background='#AAAAAA';
                  //sendEmail(e,BUTTON_SUBS_ID,"Desk",FITXA_DESK ? "Fitxa" : "Home");
                  
                  //console.log("buscar!!")
                  //setEmail("")
                  //setshowThanksSubs(true)
      
                  setEmail(" ")
                  //setshowPopPup(true);
         
                  //this.setState({ ShowPopNewsLogin: true});
                  sendAnalytics("btnRunBT","Mobile",SYMBOL_STOCK);
                  setshowPopPup(!showPopPup);
      
                 }
                 }
                 style={{ paddingLeft:40, paddingRight:40, marginTop:20,  marginBottom:0,  fontWeight:"semibold", textAlign:"center", fontSize:"large", marginLeft:5}}>
                {(1==2)/*isLoadingBT*/ ? (<CircularProgress size="44px"
                    thickness="10px" isIndeterminate color='white' mt="-6px" />) : ('Find the BEST strategy for '+SYMBOL_STOCK)}
                 </button>

                 <button className="runbacktest" id={BUTTON_SUBS_ID} marginTop={5} _hover={{ cursor: "pointer" }} 
                onClick= {(e) =>
                  {
                  
                   sendAnalytics("btnRunBT","Mobile",SYMBOL_STOCK);
            
       
                   if(selectedItemBUY.length<1) {
                     setshowErrorSubs(true);
                   }
                   else {
                     setIsLoadingBT(true);
                   DoBackTest();
                   }
                   
                  }
                  }
                 style={{ paddingLeft:40, paddingRight:40, marginTop:20,  marginBottom:0,  fontWeight:"semibold", textAlign:"center", fontSize:"large", marginLeft:5}}>
                 {isLoadingBT ? (<CircularProgress size="44px"
                           thickness="10px" isIndeterminate color='white' mt="-6px" />) : ('Run your backtest')}
                 </button>
                 
                  </VStack>
                  </div>


                  {showThanksSubs&&<Text textAlign="center" fontSize="16" mt={-6} textColor="green" fontFamily="Tahoma" fontWeight="normal" > 
            {i18n.t("gracias_news")}
           </Text>}
           {showErrorSubs&&<Text textAlign="center" fontSize="16" mt={-6} mb={0} textColor="red" fontFamily="Tahoma" fontWeight="normal" > 
           In order to execute the backtest, it is necessary, at a minimum, to specify a buy trigger
           </Text>}
                
              </GridItem>
             

             
           
       

        {showBackTest && <BackTestResult />}
      
      
      
        </SimpleGrid>
      
      

         </Box> 
       
    );
  }
  else  
  {
  return (
    
     
    <Box backgroundColor="#FFFFFF" rounded={10} width= "100%" mt={0} mb={0}>     

{showPopPup && <Box zIndex={3} background="red"position="absolute"
>
        <div className='popup'>
        <div className='popup_inner_mobile_backtest'>
        <VStack width="100%" flexDirection="column" justifyContent="center" p={5} pt={2} pb={20} alignContent="center">
        <Text textAlign="center" fontSize="24" textColor="black" fontFamily="Tahoma" fontWeight="semibold" marginBottom={2}> 
        {i18n.t("op_no_dispo")}
          </Text>
          <Text textAlign="center" mt={10} fontSize="16" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
          {i18n.t("quieres_activa")}  <strong>{i18n.t("subs_news")}</strong>
          </Text>
        
          <Text textAlign="center" mt={5} fontSize="16" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
          {i18n.t("activ_func_aviso")}
          </Text>

          <div className="formField">
            <input
              type="email"
              id="email"
              className="formFieldInput"
              placeholder={i18n.t("intro_email")}
              name="email"
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
              //value={this.state.email}
              //onChange={this.handleChange}
  
  
            />
          </div>

          <Flex marginTop={15}>
          <button className="formSignInButton" marginTop={5} _hover={{ cursor: "pointer" }} 
        onClick= {(e) =>
         {
          //sendEmail(e)
          {
            e.preventDefault();
        
            try {
            if(email.length>10 && email.includes("@") && email.includes(".")) {
        
             var templateParams = {
                email_subscriber: email,
            };
            
        
             templateParams.email_subscriber=email;
             emailjs.send(glovar.REF_EMAIL_SERVICE, glovar.REF_EMAIL_TEMPLATE, templateParams, glovar.REF_EMAIL_KEY)
             .then(function(response) {
               //console.log('SUCCESS!', response.status, response.text);
             }, function(error) {
               //console.log('FAILED...', error);
             });
            }
            else {
              
            }
        
            }
            catch(Exxx) {}
          };
          //console.log("buscar!!")
          setshowPopPup(false)
         }
         }
         style={{ paddingLeft:20, paddingRight:20, marginTop:"10px", fontWeight:"semibold", textAlign:"center", fontSize:"large", marginLeft:5}}>
         {i18n.t("subs_mayus")}</button>
         </Flex>   
         <Text  as='u' textAlign="center" paddingTop="10px" fontSize="12" textColor="black" fontFamily="Tahoma" 
         _hover={{ cursor: "pointer" }} fontWeight="normal" 
         onClick= {() =>
          {
 
           //console.log("buscar!!")
           setshowPopPup(false)
          }
          }> 
          {i18n.t("no_gracias")}
          </Text>

         </VStack>
        </div>
      </div>
      </Box>}


    <SimpleGrid   columns={14} gap={1} p={P_SIMPLE_GRID} m={0} mt={0} mb={0} 
    justifySelf="center" rounded={10} height="maxHeight" backgroundColor="#FFFFFF">

   
       <GridItem colStart={COL_START} colEnd={COL_END} rounded={10} justifyItems="center" 
        backgroundColor="#FFFFFF">
      
       <VStack width="100%" flexDirection="column" justifyContent="center" mt={4}>
       
       <Text textAlign="center"  fontSize="22"  p={0} pl={4} pr={4} textColor="black" fontFamily="Tahoma"
          background="white" fontWeight="semibold" > 
           Improve your trading on{' '}{TICKER_STOCK}.{' '}{' '}{' '}Test your strategy here!
          
           </Text>

           <Text textAlign="center"  fontSize="16"  p={4} pl={4} pr={4} textColor="black" fontFamily="Tahoma"
          background="#EEEEEE" fontWeight="normal"
          _hover={{
            bg: 'gray.100',
            cursor: 'pointer', // Muestra el cursor como enlace al pasar el ratón sobre el cuadro
          }}
          onClick= {() =>
            {  
              //VALOR.propbtItemBUY="Bollinger Band";
              //VALOR.propbtConditionBUY="Bullish cross of the central band";
              //VALOR.propbtItemSELL="Sessions";
              //VALOR.propbtConditionSELL="12";
              //VALOR.propbtBacktestTIME=24;

              try {
                setSelectedItemBUY(element.propbtItemBUY);
                setSelectedConditionBUY(element.propbtConditionBUY);
                setSelectedItemSELL(element.propbtItemSELL);
                setSESSIONSSell(element.propbtConditionSELL);
                
                setsesis1Sell(element.sessisSELL); 
                setsesis1Buy(element.sessisBUY); 
                
                //setSelectedConditionSELL(14);
                //setNumberOfMonths(24);
                SHOWselectedItemBUY=element.propbtItemBUY;
                SHOWselectedConditionBUY=element.propbtConditionBUY;
                SHOWselectedItemSELL=element.propbtItemSELL;
                SHOWselectedConditionSELL=element.propbtConditionSELL;
                SHOWselectedBacktestTIME=element.propbtBacktestTIME;
                }
                catch(Exx) {
                  setSelectedItemBUY("Bollinger Band");
                  setSelectedConditionBUY("Bullish cross of the central band");
                  setSelectedItemSELL("Sessions");
                  setSESSIONSSell(12);
                  //setSelectedConditionSELL(14);
                  //setNumberOfMonths(24);
                  SHOWselectedItemBUY="Bollinger Band";
                  SHOWselectedConditionBUY="Bullish cross of the central band";
                  SHOWselectedItemSELL="Sessions";
                  SHOWselectedConditionSELL="12";
                  SHOWselectedBacktestTIME=24;

                  element.propbtItemBUY="Bollinger Band";
                  element.propbtConditionBUY="Bullish cross of the central band";
                  element.propbtItemSELL="Sessions";
                  element.propbtConditionSELL="12";
                  element.propbtBacktestTIME=24;

                }

             sendAnalytics("btnPropBT","Desktop",SYMBOL_STOCK);
                  
              /*
              setSelectedItemBUY("Bollinger Band");
              setSelectedConditionBUY("Bullish cross of the central band");
              setSelectedItemSELL("Sessions");
              setSESSIONSSell(12);
              //setSelectedConditionSELL(14);
              //setNumberOfMonths(24);
              SHOWselectedItemBUY="Bollinger Band";
              SHOWselectedConditionBUY="Bullish cross of the central band";
              SHOWselectedItemSELL="Sessions";
              SHOWselectedConditionSELL="12";
              SHOWselectedBacktestTIME=24;
              */

              setshowBackTest(true);
              //window.scrollBy(0, 800);
              
              setScrollDesktop(true);
              //scrollToBottom(800,500);

              //DoBackTest();
              //setEmail(" ")
              //sendAnalytics("btnADDst","Desktop",SYMBOL_STOCK);
              //setshowPopPup(!showPopPup);
            }
            } > 
           <span style={{ textDecoration: 'underline' }}>EX: {element.propbtItemBUY} with {element.propbtConditionBUY} condition with a {element.propbtConditionSELL}-{element.propbtItemSELL} sell trigger. <b>Efficiency: {element.propbtEfficiency}%</b></span>
         
           </Text>
          

         </VStack>
         <VStack width="100%" flexDirection="column" justifyContent="center" p={P_VSTACK} pt={PT_VSTACK} pb={PB_VSTACK} alignContent="center">
      
            
        
        <Table variant="simple" mb={2}>
          <Thead >
            <Tr>
              <Th colSpan={2} fontSize={14}  width="42%"> Trigger BUY</Th>
              <Th colSpan={2} fontSize={14}  width="42%"> Trigger SELL</Th>
              <Th colSpan={1} fontSize={14} width="16%"> BACKTEST time</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td width="21%">
              <Select value={selectedItemBUY} onChange={handleSelectChangeBUY}>
                  {indicatorOptionsBUY.map((option, index) => (
                    <option
                      key={index}
                      value={option.value}
                      disabled={option.disabled}
                    >
                      {option.label}
                    </option>
                  ))}
                </Select>
                </Td>
              <Td width="21%">
              { (
                  <Select value={selectedConditionBUY} onChange={handleSelectChangeConditionBUY}>
                    {conditionsByIndicatorBUY[selectedItemBUY].map((condition, index) => (
                      <option key={index} value={condition}>
                        {condition}
                      </option>
                    ))}
                  </Select>
                )}
              </Td>
              <Td width="21%"><Select value={selectedItemSELL} onChange={handleSelectChangeSELL}>
                  {indicatorOptionsSELL.map((option, index) => (
                    <option
                      key={index}
                      value={option.value}
                      disabled={option.disabled}
                    >
                      {option.label}
                    </option>
                  ))}
                </Select></Td>
              {selectedItemSELL!="Sessions"&&<Td width="21%">
              { (
                  <Select value={selectedConditionSELL} onChange={handleSelectChangeConditionSELL}>
                    {conditionsByIndicatorSELL[selectedItemSELL].map((condition, index) => (
                      <option key={index} value={condition}>
                        {condition}
                      </option>
                    ))}
                  </Select>
                )}
              </Td>}


              {selectedItemSELL=="Sessions"&&<Td width="21%">
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={SESSIONSSell}
                    onChange={handleSESSIONSSell}
                    maxlength="2" // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="40%" children="sessions" />
                </InputGroup>
              </Td>}


              <Td>
              <InputGroup>
                  <Input
                  width="50%"
                    type="number"
                    value={numberOfMonths}
                    onChange={handleMonthsChange}
                    maxLength={3} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="50%" children="months" />
                </InputGroup>
              </Td>
            </Tr>
            





            <Tr>    
            {(selectedItemBUY=="MA Crossover" && selectedConditionBUY.includes("CUSTOM"))&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={MA1Buy}
                    onChange={handleMA1Buy}
                    maxLength={3} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="MA1" />
                </InputGroup>
                { selectedItemBUY=="MA Crossover" && selectedConditionBUY.includes("CUSTOM") && !selectedConditionBUY.includes("price CUSTOM")&&
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={MA2Buy}
                    onChange={handleMA2Buy}
                    maxLength={3} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="MA2" />
                  </InputGroup>}
              </Td>}

              {(selectedItemBUY=="RSI")&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={RSI1Buy}
                    onChange={handleRSI1Buy}
                    maxlength="2" // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="RSI B" />
                </InputGroup>
              </Td>}

          

              {(/*selectedItemBUY!="MA Crossover" &&*/ (selectedItemBUY.length>1))&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={sesis1Buy}
                    onChange={handleSESSIS1Buy}
                    maxLength={2} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="40%" children="sessions" />
                </InputGroup>
                <Text ml={1} width="70%" fontSize="14" mt={1} textColor="black" fontFamily="Tahoma" fontWeight="normal"> 
                condition BUY triggered
                </Text>
              </Td>}


              {(/*selectedItemSELL!="MA Crossover" &&*/ selectedItemSELL!="WHITOUT Trigger SELL" && selectedItemSELL!="Sessions"
               && (selectedItemSELL.length>1))&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={sesis1Sell}
                    onChange={handleSESSIS1Sell}
                    maxLength={2} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="40%" children="sessions" />
                </InputGroup>
                <Text ml={1} width="70%" fontSize="14" mt={1} textColor="black" fontFamily="Tahoma" fontWeight="normal"> 
                condition SELL triggered
                </Text>
              </Td>}



              {(1==2 && selectedItemBUY=="MA Crossover" && selectedConditionBUY.includes("CUSTOM") && !selectedConditionBUY.includes("price CUSTOM"))&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={MA2Buy}
                    onChange={handleMA2Buy}
                    maxLength={3} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="MA2" />
                </InputGroup>
              </Td>}
            
              {(selectedItemSELL=="MA Crossover" && selectedConditionSELL.includes("CUSTOM"))&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={MA1Sell}
                    onChange={handleMA1Sell}
                    maxLength={3} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="MA1" />
                </InputGroup>
              
              {(selectedItemSELL=="MA Crossover" && selectedConditionSELL.includes("CUSTOM") && !selectedConditionSELL.includes("price CUSTOM"))&&
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={MA2Sell}
                    onChange={handleMA2Sell}
                    maxLength={3} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="MA2" />
                </InputGroup>}
              </Td>}

              {(selectedItemSELL=="RSI")&&<Td>
              <InputGroup>
                  <Input
                  width="30%"
                    type="number"
                    value={RSI1Sell}
                    onChange={handleRSI1Sell}
                    maxLength={2} // Limita la longitud del número a 3 dígitos
                    pattern="\d*" // Permite solo caracteres numéricos
                  />
                  <InputRightAddon width="25%" children="RSI S" />
                </InputGroup>
              </Td>}

            </Tr>








          </Tbody>
        </Table>
     

        <Center>
        <Box marginRight="4">
        <button className="runbacktest" id={BUTTON_SUBS_ID} marginTop={5} _hover={{ cursor: "pointer" }} 
          onClick= {(e) =>
           {
            setEmail(" ")
            //setshowPopPup(true);
   
            //this.setState({ ShowPopNewsLogin: true});
            sendAnalytics("btnFINDst","Desktop",SYMBOL_STOCK);
            setshowPopPup(!showPopPup);
           
           }
           }
           style={{ paddingLeft:40, paddingRight:40, marginTop:20,  marginBottom:0, 
           backgroundColor: (1==2)/*isLoadingBT*/ ? "gray" : "black",
           fontWeight:"semibold", textAlign:"center", fontSize:"large", marginLeft:5}}
           >
           {(1==2)/*isLoadingBT*/ ? (<CircularProgress size="44px"
                    thickness="10px" isIndeterminate color='white' mt="-6px" />) : ('Find the BEST strategy for '+SYMBOL_STOCK)}
           </button>
           </Box>


           <Box marginLeft="4">
            <button className="runbacktest" id={BUTTON_SUBS_ID} marginTop={5} _hover={{ cursor: "pointer" }} 
          onClick= {(e) =>
           {
            //document.getElementById(BUTTON_SUBS_ID).disabled = true;
            //document.getElementById(BUTTON_SUBS_ID).style.background='#AAAAAA';
            //sendEmail(e,BUTTON_SUBS_ID,"Desk",FITXA_DESK ? "Fitxa" : "Home");
            
            //console.log("buscar!!")
            sendAnalytics("btnRunBT","Desktop",SYMBOL_STOCK);
            //setEmail("")
            //setshowThanksSubs(true)

            /*
            setEmail(" ")
            //setshowPopPup(true);
            sendAnalytics("Desktop",SYMBOL_STOCK);
            //this.setState({ ShowPopNewsLogin: true});
            setshowPopPup(!showPopPup);
            */

            if(selectedItemBUY.length<1) {
              setshowErrorSubs(true);
            }
            else {
              setIsLoadingBT(true);
            DoBackTest();
            }
            
           }
           }
           style={{ paddingLeft:40, paddingRight:40, marginTop:20,  marginBottom:0, 
           backgroundColor: isLoadingBT ? "gray" : "black",
           fontWeight:"semibold", textAlign:"center", fontSize:"large", marginLeft:5}}
           >
           {isLoadingBT ? (<CircularProgress size="44px"
                    thickness="10px" isIndeterminate color='white' mt="-6px" />) : ('Run your backtest')}
           </button>
           </Box>
           </Center>

            </VStack>
            {showThanksSubs&&<Text textAlign="center" fontSize="16" mt={-6} textColor="green" fontFamily="Tahoma" fontWeight="normal" > 
            {i18n.t("gracias_news")}
           </Text>}
           {showErrorSubs&&<Text textAlign="center" fontSize="16" mt={-6} mb={0} textColor="red" fontFamily="Tahoma" fontWeight="normal" > 
           In order to execute the backtest, it is necessary, at a minimum, to specify a buy trigger
           </Text>}
            
        </GridItem>

        {showBackTest && <BackTestResult />}
       


  </SimpleGrid>


  


     




   </Box> 
 
  );
}
}
