import {useState} from 'react'
import { Link } from 'react-router-dom'
import './forms.css'
//import {signInWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
//import {auth} from './firebase'
import {useNavigate} from 'react-router-dom'
import {useAuthValue} from './AuthContext'

import { IconButton } from "@chakra-ui/button";
import { SimpleGrid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Grid } from "@chakra-ui/layout";
import { Center } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { FiPlus } from "react-icons/fi";
import { HStack } from "@chakra-ui/layout";
import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";

import glovar from './components/content/glovar';
import HeaderMenu from './components/content/HeaderMenu';



import i18n from './i18n'

import { Spacer } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";

import "./App.css";
import { getToken, removeUserSession, setUserSession,setCurrent_porfolio_ED,setUserSessionStocks } from './utils/Common';


function Register(){

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('') 
  const [username, setUsername] = useState('') 
  const [newsletter, setNewsletter] = useState('') 

  const [error, setError] = useState('')
  //const {setTimeActive} = useAuthValue()
  const navigate = useNavigate()

  
  const register = e => {
    e.preventDefault()

    
 let headers = new Headers(); 
 headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API + ":" + glovar.PASS_API));
 headers.set('Parameters', 'Basic ' + btoa(username + ":" +email + ":" + password+ ":" +newsletter));


 fetch(glovar.URL_BACKEND_PROD+'/api/registeruser',{ method:'POST', //mode: 'no-cors',
 headers: headers,
 })
 //fetch('http://localhost:3010/api/loginuser?email='+email+'&password='+password)
.then(res => res.json())
.then(res => {
  //glovar.TOPTRADERSLIST=res;
  //console.log(res)

  //auth/alreadyregistered
  //ok_registered
  //"ERROR_SIGNUP"

  if(res=="auth/user-not-found" || res=="auth/wrong-password") {
    try { setError("ERROR: "+i18n.t("email_try_again"));  }
    catch(Exx) {setError("ERROR: The email or password you entered is incorrect. Please try again.");   }
    return;
  }

  if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {
    setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
    return;
  }
  
  if(res=="ok_registered")
  {
    glovar.USER_LOADED=true;
    glovar.MODE_USER=1;
    glovar.MODE_USER_WEB=1;
    glovar.USER_USERNAME=username;
    glovar.USER_EMAIL=email;
    glovar.FORCE_TRUE_USERFETCH=true;

    //console.log("REGISTRADO USER: "+username)
    //console.log("REGISTRADO USER: "+email)
    try {
     // setUserSession("token123", glovar.CURRENT_TRADER);
     createNewUser();

      try {
      setUserSession("token123", glovar.USER_USERNAME, glovar.USER_EMAIL,glovar.MODE_USER);
     }
     catch(Exx) {}
    }
    catch(Exx) {}
    //this.setState({});

      //navigate('/home')
      window.location.replace('/home')
  }
 })
    //.catch(err => alert(err.message))
    //.catch(err => setError(err.message))
    .catch((err) => {  
      //console.log(JSON.stringify(err)); 
      //{"code":"auth/user-not-found","customData":{},"name":"FirebaseError"}

      try {
        if(err.code=="auth/user-not-found") {
          setError("ERROR: The email or password you entered is incorrect. Please try again.");  //Please try again or contact support if you are unable to access your account");
        }
        else {
          setError("Error")
        }
      }
      catch(Excp) {setError("Error");}//+Excp)}
      //setError(err.message)
    }
    
   )
  }

 
  async function createNewUser() {

    glovar.CURRENT_USER=null;
   
    
    
     
    
    var SHOW_CARTERA=0;
     
    const lang =glovar.APP_LANG;
    var NEW_PRIORITY=-1;
    SHOW_CARTERA=1;
    
         
    var cartera = {
        rentab: 0,
        name: 'GLOBAL STOCKS',
        idCartera: 'ID_GLOBAL',
        descript: 'Global stock portfolio description',
        risk_level: 3,
        max_level: 5,
        countriesID: ' ',
        activos: '1,2,3',
        op_cerradas: 0,
        rentmedia_op: 0,
        sentido: 1,
        temporal: 1,
        historial:'',
        cartera_text:'',
        cartera_ed:[],
        cartera:[],
        favoritos:[], 
        capital:glovar.DEFAULT_CAPITAL,
        capitalUsed:0,
        invertido:0,
        moneda:' ',
        profit:0,
        priority:0,
        profit_acum:0,
        rentab_acum:0,
        type_cartera:2, //por defecto solo la pueden ver los premiums!
        op_win: 8,
        followers: 333,
        cap_inicial:1000000,
        ranking:1,
        lastDataUpdateCartera_ed:0,
        lastDataUpdateFavoritos:0,
        lastDataUpdateHistorial:0,
        lastDataUpdatePortfolio: 0,
        subscribed:false
    };
    
      
    var newCarteraED = {
            rentab:cartera.rentab,
            max_level:cartera.max_level,
            garantia:cartera.garantia,
            countriesID:cartera.countriesID,
            cartera_text:cartera.cartera_text,
            temporal:cartera.temporal,
            descript:cartera.descript,
            rentmedia_op:cartera.rentmedia_op,
            profit_acum:cartera.profit_acum,
            invertido:cartera.invertido,
            activos:cartera.activos,
            lastDataUpdatePortfolio:cartera.lastDataUpdatePortfolio,
            rentab_acum:cartera.rentab_acum,
            currency:cartera.currency,
            capital:cartera.capital,
            risk_level:cartera.risk_level,
            currentmoney:cartera.currentmoney,
            op_cerradas:cartera.op_cerradas,
            profit:cartera.profit,
            idCartera:cartera.idCartera,
            historial:cartera.historial,
            capitalUsed:cartera.capitalUsed,
            sentido:cartera.sentido,
            lastDataUpdateHistorial:cartera.lastDataUpdateHistorial,
            lastDataUpdateFavoritos:cartera.lastDataUpdateFavoritos,
            name:cartera.name,
            lastDataUpdateCartera_ed:cartera.lastDataUpdateCartera_ed,
            cartera:cartera.cartera,
            ranking: 1,
            FOLLOWERS_LIST: [0],
            SHOW_PUBLIC_PROFILE_USER: glovar.DEFAULT_SHOW_PUBLIC_PROFILE_USER,
            SHOW_PUBLIC_PROFILE_USER_INFO: glovar.DEFAULT_SHOW_PUBLIC_PROFILE_USER_INFO,
            SHOW_PUBLIC_PROFILE_BTNFOLLOW: glovar.DEFAULT_SHOW_PUBLIC_PROFILE_BTNFOLLOW,
            SHOW_PUBLIC_CARTERA: glovar.DEFAULT_SHOW_PUBLIC_CARTERA,
            SHOW_PUBLIC_CARTERA_TIT_BENEF: glovar.DEFAULT_SHOW_PUBLIC_CARTERA_TIT_BENEF,
            SHOW_PUBLIC_HISTORIAL: glovar.DEFAULT_SHOW_PUBLIC_HISTORIAL,
            SHOW_PUBLIC_HISTORIAL_TIT_BENEF:  glovar.DEFAULT_SHOW_PUBLIC_HISTORIAL_TIT_BENEF,
            SHOW_PUBLIC_CARTERA_ONLY_FOLLOW: glovar.DEFAULT_SHOW_PUBLIC_CARTERA_ONLY_FOLLOW,
            SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW: glovar.DEFAULT_SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW,
            NUM_FOLLOWERS: 0,
            NUM_VISITAS: 0,
            NUM_LIKES: 0,
            list_following: [],
            list_prefollowing: [],
            list_followers: [],
            list_prefollowers: []
    }
  
    //console.log("añadimos cartera ED!!!")
    cartera.cartera_ed=newCarteraED;
            
      try 
      {
       if(glovar.listTopTradersSUBS!=null && glovar.listTopTradersSUBS.length>0)
       {
        for(let z=0; z<glovar.listTopTradersSUBS.length; z++)
        {
         try { if(glovar.listTopTradersSUBS[z].id==cartera.id) { 
           cartera.subscribed=true;
           //console.log('TROBAT SUBSCRIBED!!!')
           break } } catch(Exxxx) {}
        }
       }
      }
      catch(Exxxxa) {}
      //console.log('cartera.type_cartera:'+cartera.type_cartera);
    
      //const ID_CARTERA=docUser.idCartera');
    
    
      //glovar.TOPTRADERS_PRO1.push(cartera);
      glovar.CURRENT_USER=cartera;
  
      //console.log("glovar.CURRENT_USER:"+JSON.stringify(glovar.CURRENT_USER));
  
      glovar.current_porfolio_ED=cartera.cartera_ed;
  
      try { glovar.current_porfolio_ED.lastDataUpdateCartera_ed=cartera.lastDataUpdateCartera_ed; } catch(Exxx) {}
      try { glovar.current_porfolio_ED.lastDataUpdateFavoritos=cartera.lastDataUpdateFavoritos; } catch(Exxx) {}
      try { glovar.current_porfolio_ED.lastDataUpdateHistorial=cartera.lastDataUpdateHistorial; } catch(Exxx) {}
      try { glovar.current_porfolio_ED.lastDataUpdatePortfolio=cartera.lastDataUpdatePortfolio;  } catch(Exxx) {}
      
      glovar.current_porfolio_ED.moneda=glovar.DEFAULT_MONEDA;
      glovar.current_porfolio_ED.currency=glovar.DEFAULT_CURRENCY;
      glovar.current_porfolio_ED.capital=glovar.DEFAULT_CAPITAL;

  
    setCurrent_porfolio_ED(glovar.current_porfolio_ED);
  
     
     var ARRAY_COTIS="";
     glovar.CURRENT_FAVORITOS=null;
     glovar.CURRENT_FAVORITOS = [];
  
     glovar.CURRENT_PORTFOLIO=null;
     glovar.CURRENT_PORTFOLIO = [];
  
     glovar.CURRENT_HISTORIAL=null;
     glovar.CURRENT_HISTORIAL = [];
  
     let j=0;
     //for(let j=0;j<glovar.TOPTRADERS_PRO1.length;j++)
     {
      //if(glovar.TOPTRADERS_PRO1[j].idCartera==glovar.CURRENT_TRADER)
      try 
      {
        //console.log("SE CUMPLE LA CONDICION!!!")
        //glovar.CURRENT_HISTORIAL=glovar.CURRENT_USER.historial.split('},');
        glovar.CURRENT_HISTORIAL=glovar.CURRENT_USER.historial;
      }
      catch(Exxx) {}
     }
  
     //for(let j=0;j<glovar.TOPTRADERS_PRO1.length;j++)
     {
      
  
      //console.log("Hola 0000")
      glovar.CURRENT_FAVORITOS=glovar.CURRENT_USER.favoritos;
      //console.log("Hola 0001:"+glovar.CURRENT_USER.favoritos)
        
  
     }
  
     try 
     {
       glovar.CURRENT_PORTFOLIO=glovar.CURRENT_USER.cartera;
       
     
     }
     catch(Exxx) {}
  
     setUserSessionStocks("token123", glovar.CURRENT_FAVORITOS, glovar.CURRENT_HISTORIAL,glovar.CURRENT_PORTFOLIO); 

  
     glovar.FIREBASE_LOADED=1;

     if(1==1) {
      try 
           {
            var headers = new Headers(); 
   
            //console.log("glovar.USER_API_POST:"+glovar.USER_API_POST)
            //console.log("glovar.PASS_API_POST:"+glovar.PASS_API_POST)
   
            headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
            headers.set('Content-Type', 'application/json');
        
            //glovar.CURRENT_USER=cartera;
            //glovar.current_porfolio_ED=cartera.cartera_ed;
                         
             //console.log(JSON.stringify(headers))
   
             var carteraPARSED=JSON.stringify(glovar.CURRENT_PORTFOLIO);
             carteraPARSED = carteraPARSED.replace('[', '');
             carteraPARSED = carteraPARSED.replace(']', '');
   
             var historialPARSED=JSON.stringify(glovar.CURRENT_HISTORIAL);
             historialPARSED = historialPARSED.replace('[', '');
             historialPARSED = historialPARSED.replace(']', '');
   
             var favoritosPARSED=JSON.stringify(glovar.CURRENT_FAVORITOS);
             favoritosPARSED = favoritosPARSED.replace('[', '');
             favoritosPARSED = favoritosPARSED.replace(']', '');
   
            const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
                  //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                  //method: "POST",
                  method: "PUT",  
                  headers: headers,
                 
                  body: 
                  JSON.stringify({ 
                   rentab: glovar.current_porfolio_ED.rentab,
                   name: glovar.USER_USERNAME,//glovar.CURRENT_USER.name,
                   idCartera:  glovar.USER_USERNAME, //glovar.CURRENT_USER.idCartera,
                   id:  0,//glovar.USER_USERNAME,//glovar.CURRENT_USER.name,//glovar.TOPTRADERS_PRO1[j].id,
                   descript: glovar.current_porfolio_ED.descript,//glovar.TOPTRADERS_PRO1[j].descript,
                   risk_level: glovar.current_porfolio_ED.risk_level,
                   max_level: glovar.current_porfolio_ED.max_level,
                   countriesID:glovar.current_porfolio_ED.countriesID,
                   activos: glovar.current_porfolio_ED.activos,
                   op_cerradas: glovar.current_porfolio_ED.op_cerradas,
                   rentmedia_op:glovar.current_porfolio_ED.rentmedia_op,
                   sentido: glovar.current_porfolio_ED.sentido,
                   temporal: glovar.current_porfolio_ED.temporal,
                   historial: historialPARSED,//JSON.stringify(glovar.CURRENT_HISTORIAL),
                   cartera: carteraPARSED,//JSON.stringify(glovar.CURRENT_PORTFOLIO),//glovar.TOPTRADERS_PRO1[j].cartera_text,
                   favoritos: favoritosPARSED,
                   //cartera_text:glovar.TOPTRADERS_PRO1[j].cartera_text,
                   //cartera:glovar.TOPTRADERS_PRO1[j].cartera,
                   capital:glovar.current_porfolio_ED.capital,
                   invertido:glovar.current_porfolio_ED.invertido,
                   moneda:glovar.current_porfolio_ED.moneda,
                   profit:glovar.current_porfolio_ED.profit,
                   profit_acum:glovar.current_porfolio_ED.profit_acum,
                   rentab_acum:glovar.current_porfolio_ED.rentab_acum,
                   currency:glovar.current_porfolio_ED.currency,
                   priority:glovar.current_porfolio_ED.priority,
                   rentab_acum:glovar.current_porfolio_ED.rentab_acum,
                   lastDataUpdateCartera_ed:glovar.current_porfolio_ED.lastDataUpdateCartera_ed, 
                   lastDataUpdateFavoritos:glovar.current_porfolio_ED.lastDataUpdateFavoritos, 
                   lastDataUpdateHistorial:glovar.current_porfolio_ED.lastDataUpdateHistorial,
                   lastDataUpdatePortfolio:glovar.current_porfolio_ED.lastDataUpdatePortfolio,
                   type_cartera:"0",//glovar.TOPTRADERS_PRO1[j].type_cartera, //por defecto solo la pueden ver los premiums!
                   op_win:"0",// glovar.TOPTRADERS_PRO1[j].op_win,
                   followers:"0",// glovar.TOPTRADERS_PRO1[j].followers,
                   cap_inicial:"0",//glovar.TOPTRADERS_PRO1[j].cap_inicial,
                   ranking:"0",//glovar.TOPTRADERS_PRO1[j].ranking,
                   FOLLOWERS_LIST:"0",//:glovar.TOPTRADERS_PRO1[j].FOLLOWERS_LIST
                   ranking: 1,
                   FOLLOWERS_LIST: [0],
                   SHOW_PUBLIC_PROFILE_USER: glovar.current_porfolio_ED.SHOW_PUBLIC_PROFILE_USER,
                   SHOW_PUBLIC_PROFILE_USER_INFO: glovar.current_porfolio_ED.SHOW_PUBLIC_PROFILE_USER_INFO,
                   SHOW_PUBLIC_PROFILE_BTNFOLLOW: glovar.current_porfolio_ED.SHOW_PUBLIC_PROFILE_BTNFOLLOW,
                   SHOW_PUBLIC_CARTERA: glovar.current_porfolio_ED.SHOW_PUBLIC_CARTERA,
                   SHOW_PUBLIC_CARTERA_TIT_BENEF: glovar.current_porfolio_ED.SHOW_PUBLIC_CARTERA_TIT_BENEF,
                   SHOW_PUBLIC_HISTORIAL: glovar.current_porfolio_ED.SHOW_PUBLIC_HISTORIAL,
                   SHOW_PUBLIC_HISTORIAL_TIT_BENEF: glovar.current_porfolio_ED.SHOW_PUBLIC_HISTORIAL_TIT_BENEF,
                   SHOW_PUBLIC_CARTERA_ONLY_FOLLOW: glovar.current_porfolio_ED.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW,
                   SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW: glovar.current_porfolio_ED.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW,
                   NUM_FOLLOWERS: 0,
                   NUM_VISITAS: 0,
                   NUM_LIKES: 0,
                   list_following: [],
                   list_prefollowing: [],
                   list_followers: [],
                   list_prefollowers: []
     
              //id:"1",//glovar.TOPTRADERS_PRO1[j].id,
              //descript: "que pasa nen me voy de subidon!"
                 })
            }) 
   
            //console.log("pujat correctament dades user OK1 ");  //+glovar.CURRENT_USER.name)
           }
           catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
         }
  }


  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  {  
    if(glovar.MOBILE_SIGNUP_ALLOWED!=1) 
    {
       
    return (
      <Box onClick= {() => window.location.replace('/home') }>
       
        
       <Box p={10} pb={0}>
           <Image src={`${process.env.PUBLIC_URL}/assets/logopro1xsmall.png`} h="100px" _hover={{ cursor: "pointer" }}
        onClick= {() =>  {
          //try { sendEventGA("button_sup","pslogoFitxMB", "pslogoFitxMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          window.location.replace('/home') 
        }}
       /> 
       </Box>
     
      <GridItem colSpan={2}  height="100vh" >
      <div className="formCenterCreateAccount" background={"yellow"}>
      <Text style={{fontSize:"1.8em", fontWeight:"bold", marginBottom:"3pc" }}>{i18n.t("signup")}</Text>
     
        
      <Text  fontSize="1.1em" marginBottom={10} marginRight={3} marginLeft={-3} fontWeight="normal" textColor="gray.800">
          The <b>mobile version</b> of the website has temporarily <b>limited functionality</b> and does not allow new user registration.</Text>
          <Text  fontSize="1.1em" marginBottom={10}  marginRight={3}  marginLeft={-3} textColor="gray.800">
            To <b>create your account</b>, you need to access the <b>Desktop version</b> at Pro1X.com</Text>
          <Text  fontSize="1.1em" marginBottom={10}  marginRight={3}marginLeft={-3}  textColor="gray.800">
          Once registered, you can <b>log in to the Desktop version</b> and access <b>all the options and tools</b> offered by Pro1X.com
           </Text>


      {1==2&&<Text  fontSize="1.1em" marginBottom={10} marginRight={3} marginLeft={-3} fontWeight="normal" textColor="gray.800">
          La web <b>versión mobile</b> tiene temporalmente <b>limitadas algunas funcionalidades</b> y no permite registro de nuevos usuarios.</Text>}
          {1==2&&<Text  fontSize="1.1em" marginBottom={10}  marginRight={3}  marginLeft={-3} textColor="gray.800">
            Para <b>crear tu usuario</b> es necesario acceder a la <b>versión Desktop (ordenador)</b> en Pro1X.com</Text>}
            {1==2&&<Text  fontSize="1.1em" marginBottom={10}  marginRight={3}marginLeft={-3}  textColor="gray.800">
            Una vez registrado, podrás <b>iniciar sesión en la versión Desktop</b> y acceder a <b>todas las opciones y herramientas</b> que ofrece Pro1X.com
           </Text>}
  
           
         
        
      </div>
      </GridItem>
      
      </Box>
    );
   }
   else {
    return (
      <Box>
      
  
      <HeaderMenu showSearch={false}/>
  
      <div className="mobformCenterCreateAccount" background={"yellow"}>
      <Text style={{fontSize:"1.8em", fontWeight:"bold", marginBottom:"1pc" }}>Create an account</Text>
      {error && <div className='auth__error'>{error}</div>}
        <form className="formFields" onSubmit={register}>
  
        <div className="mobformFieldregister">
            <label className="formFieldLabel" htmlFor="text">
             Username
            </label>
            <input
              type="text"
              id="username"
              className="formFieldInput"
              placeholder="Enter your username"
              name="username"
              value={username}
              required
              onChange={e => setUsername(e.target.value)}
              //value={this.state.email}
              //onChange={this.handleChange}
  
  
            />
          </div>
  
  
  
          <div className="mobformFieldregister">
            <label className="formFieldLabel" htmlFor="email">
              E-Mail
            </label>
            <input
              type="email"
              id="email"
              className="formFieldInput"
              placeholder="Enter your email address"
              name="email"
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
              //value={this.state.email}
              //onChange={this.handleChange}
  
  
            />
          </div>
  
          <div className="mobformFieldregister">
            <div className="div_hor_left">
            <label className="formFieldLabel" htmlFor="password">
              Password
            </label>
            </div>
       
            <input
              type="password"
              id="password"
              className="formFieldInput"
              placeholder="Enter your password"
              name="password"
              value={password}
              required
              onChange={e => setPassword(e.target.value)}
            />
          </div>
  
          <div className="mobformField_nomargin">
              <label className="formFieldCheckboxLabel">
                <input
                  className="formFieldCheckbox"
                  type="checkbox"
                  name="hasAgreed"
                  onChange={e => setNewsletter(e.target.value)}
                  //value={this.state.hasAgreed}
                  //onChange={this.handleChange}
                />{" "}
                I agree to receive <b>FREE</b> info about quotes, financial markets and news related to Pro1X.com
              </label>
            </div>
  
  
  
          <div className="mobformField">
            <button className="formFieldButton">Create an account</button>{" "}
            <Text fontSize={11} fontWeight="normal" marginLeft={"5px"} marginTop={"10px"} textColor="gray"> 
            By creating a user account you agree to the <a href="null" className="formFieldTermsLink">Terms and Conditions of Service </a> and <a href="null" className="formFieldTermsLink"> Privacy Policy</a>.</Text>
          </div>
          
          <SimpleGrid columns={3} gap={0} width="80%" marginTop="-1%" marginLeft="0%"  alignContent="center" textAlign="center">
      <GridItem colSpan={2}>
          <Text
        fontWeight="300"
        size="14"
        
        textColor="gray"
      >
        Already have an account?
      </Text>
      </GridItem>
      <GridItem colSpan={1}>
          
      <Text
        fontWeight="bold"
        size="14"
        //textDecorationLine={"underline"}
        textColor="blackAlpha.800"
        _hover={{ cursor: "pointer" }}
      >
        <Link to='/login'>Sign in</Link>
      </Text>
      
      </GridItem>
      </SimpleGrid>
         
        </form>
      </div>
     
      </Box>
    );
   }
  }
    else {
  return (
    <Box>
      <SimpleGrid columns={6} gap={3} >
      <GridItem colSpan={4} background={"#000000"/*"#F8F8F8"*/} height="100vh">
      <Box   _hover={{ cursor: "pointer" }}>
        <div onClick= {() => window.location.replace('/home') }>
        <img className="verticalhorizontal" src='assets/logopro1xsmall1.png' w={60}  />
        </div>
        </Box>
        
    </GridItem>

   
    <GridItem colSpan={2}  height="100vh" >
    <div className="formCenterCreateAccount" background={"yellow"}>
    <Text style={{fontSize:"1.8em", fontWeight:"bold", marginBottom:"2pc" }}>Create an account</Text>
    {error && <div className='auth__error'>{error}</div>}
      <form className="formFields" onSubmit={register}>

      <div className="formField">
          <label className="formFieldLabel" htmlFor="text">
           Username
          </label>
          <input
            type="text"
            id="username"
            className="formFieldInput"
            placeholder="Enter your username"
            name="username"
            value={username}
            required
            onChange={e => setUsername(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange}


          />
        </div>



        <div className="formField">
          <label className="formFieldLabel" htmlFor="email">
            E-Mail
          </label>
          <input
            type="email"
            id="email"
            className="formFieldInput"
            placeholder="Enter your email address"
            name="email"
            value={email}
            required
            onChange={e => setEmail(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange}


          />
        </div>

        <div className="formField">
          <div className="div_hor_left">
          <label className="formFieldLabel" htmlFor="password">
            Password
          </label>
          </div>
     
          <input
            type="password"
            id="password"
            className="formFieldInput"
            placeholder="Enter your password"
            name="password"
            value={password}
            required
            onChange={e => setPassword(e.target.value)}
          />
        </div>

        <div className="formField_nomargin">
            <label className="formFieldCheckboxLabel">
              <input
                className="formFieldCheckbox"
                type="checkbox"
                name="hasAgreed"
                onChange={e => setNewsletter(e.target.value)}
                //value={this.state.hasAgreed}
                //onChange={this.handleChange}
              />{" "}
              I agree to receive <b>FREE</b> info about quotes, financial markets and news related to Pro1X.com
            </label>
          </div>



        <div className="formField">
          <button className="formFieldButton">Create an account</button>{" "}
          <Text fontSize={11} fontWeight="normal" marginLeft={"20px"} marginTop={"5px"} textColor="gray"> 
          By creating a user account you agree to the <a href="null" className="formFieldTermsLink">Terms and Conditions of Service </a> and <a href="null" className="formFieldTermsLink"> Privacy Policy</a>.</Text>
        </div>
        
        <SimpleGrid columns={3} gap={0} width="65%" marginTop="-1%" marginLeft="0%"  alignContent="center" textAlign="center">
    <GridItem colSpan={2}>
        <Text
      fontWeight="300"
      size="14"
      
      textColor="gray"
    >
      Already have an account?
    </Text>
    </GridItem>
    <GridItem colSpan={1}>
        
    <Text
      fontWeight="bold"
      size="14"
      //textDecorationLine={"underline"}
      textColor="blackAlpha.800"
      _hover={{ cursor: "pointer" }}
    >
      <Link to='/login'>Sign in</Link>
    </Text>
    
    </GridItem>
    </SimpleGrid>
       
      </form>
    </div>
    </GridItem>
    </SimpleGrid>
    </Box>
  );
}
}

export default Register