import "./ToptraderList_styles.scss";
import { useState, useRef } from "react";
import PortfolioListItem from "./PortfolioListItem";
//import { useFetchTodoToptraders } from "../utils/useFetchTodoToptraders";
import { useFetchTodoPortfolio } from "../utils/useFetchTodoPortfolio";
import glovar from './glovar';
import eventBus from "../eventBus";
import { getToken, removeUserSession, setUserSession, setPortfolio, setCurrent_porfolio_ED, setHistorial } from '../../utils/Common';

export default ({ }) => {
  
  //console.log("PORTFOLIO LIST!!!!!!!!!!!!!!!!!!!!!");
  //console.log("PORTFOLIO LIST glovar.current_porfolio_ED.invertido:"+glovar.current_porfolio_ED.invertido);
  
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  
  const Tag = ({ data }) => {
    return <li>{data}</li>;
  };

  if(!glovar.CURRENT_PORTFOLIO) {
    return null;
  }

 
  let todoPortfolio = [
    { pos: 0, title:"IAG", symbol:"IAG.MC", coti:3444, acciones:2000, multi:1, sentido:1, moneda:"€", entrada:1.21, benef:566.78, rent:4.55, obj:99.99, stop:6.67, fent:"09-09-22", fsal:"09-09-22" },//salida:45.69,
    { pos: 1, title:"EUR/USD", symbol:"EURUSD=X", coti:3444, acciones:2.6, multi:10000, sentido:1, moneda:"$", entrada:1.003, benef:566.78, rent:4.55, obj:99.99, stop:6.67, fent:"09-09-22", fsal:"09-09-22" },//salida:45.69,
    { pos: 2, title:"EURO 50", symbol:"^STOXX50E", coti:3444, acciones:2, sentido:2, multi:2, moneda:"€", entrada:3510, benef:566.78, rent:4.55, obj:99.99, stop:6.67, fent:"09-09-22", fsal:"09-09-22" },//salida:45.69,
    { pos: 3, title:"S&P 500", symbol:"IAG.MC", coti:3444, acciones:2000, multi:1, sentido:1, moneda:"€", entrada:1.21, benef:566.78, rent:4.55, obj:99.99, stop:6.67, fent:"09-09-22", fsal:"09-09-22" },//salida:45.69,
    { pos: 4, title:"Nasdaq 100", symbol:"EURUSD=X", coti:3444, acciones:2.6, multi:10000, sentido:1, moneda:"$", entrada:1.003, benef:566.78, rent:4.55, obj:99.99, stop:6.67, fent:"09-09-22", fsal:"09-09-22" },//salida:45.69,
    { pos: 5, title:"Digital Ocean", symbol:"^STOXX50E", coti:3444, acciones:2, sentido:2, multi:2, moneda:"€", entrada:3510, benef:566.78, rent:4.55, obj:99.99, stop:6.67, fent:"09-09-22", fsal:"09-09-22" },//salida:45.69,
    { pos: 7, title:"Meta", symbol:"EURUSD=X", coti:3444, acciones:2.6, multi:10000, sentido:1, moneda:"$", entrada:1.003, benef:566.78, rent:4.55, obj:99.99, stop:6.67, fent:"09-09-22", fsal:"09-09-22" },//salida:45.69,
    { pos: 8, title:"EUR/CHF", symbol:"^STOXX50E", coti:3444, acciones:2, sentido:2, multi:2, moneda:"€", entrada:3510, benef:566.78, rent:4.55, obj:99.99, stop:6.67, fent:"09-09-22", fsal:"09-09-22" },//salida:45.69,
    ];

    try {
      //console.log("xglovar.CURRENT_PORTFOLIO:"+glovar.CURRENT_PORTFOLIO)
    }
    catch(Exx) {}
    try { todoPortfolio=glovar.CURRENT_PORTFOLIO.slice(); } catch(Exxx) {}
  
    var ARRAY_PORT_COTIS="";
    for(let j=0; j<todoPortfolio.length; j++) 
    {
      if(todoPortfolio[j].symbol) {
        ARRAY_PORT_COTIS=ARRAY_PORT_COTIS+todoPortfolio[j].symbol+","
      }
    }  

    //###################################################################################//
    // Now, we add de par_divisa cotis if you needed                            #########//
    //###################################################################################//


    try 
    {
     if(!glovar.current_porfolio_ED.currency) {
      if(glovar.current_porfolio_ED.moneda=='€') { glovar.current_porfolio_ED.currency='EUR'; }
      if(glovar.current_porfolio_ED.moneda=='£') { glovar.current_porfolio_ED.currency='GBP'; }
      if(glovar.current_porfolio_ED.moneda=='$') { glovar.current_porfolio_ED.currency='USD'; }
     }
    }
    catch(Exxx) {}
  
    var PARDIVISAS_A_PILLAR=[]
    glovar.GLOBAL_CURRENT_PARTDIVISAS =[]
    
  
    for (let j = 0; j < glovar.CURRENT_PORTFOLIO.length; j++) 
    {
     try 
     { 
       if(glovar.CURRENT_PORTFOLIO[j].symbol.length>0) 
       { 
         //Si la divisa del valor es diferente a la divisa de la cartera
         if(glovar.current_porfolio_ED.currency!=glovar.CURRENT_PORTFOLIO[j].currency)
         {
           //console.log('divisa diferente a la local:'+glovar.CURRENT_PORTFOLIO[j].currency)
          if(PARDIVISAS_A_PILLAR.length<1) 
          {
           var par1={ symbol:'', currency:'', price:'' }
  
           par1.symbol=''+(glovar.CURRENT_PORTFOLIO[j].currency || '').toUpperCase()+(glovar.current_porfolio_ED.currency || '').toUpperCase()+'=X';
           par1.currency=''+(glovar.CURRENT_PORTFOLIO[j].currency || '').toUpperCase();
           par1.price='0';
          
           glovar.GLOBAL_CURRENT_PARTDIVISAS.push(par1)
           PARDIVISAS_A_PILLAR.push(glovar.CURRENT_PORTFOLIO[j].currency+glovar.current_porfolio_ED.currency+'=X')
          }
          else
          {
           
            if(!JSON.stringify(PARDIVISAS_A_PILLAR).includes(''+glovar.CURRENT_PORTFOLIO[j].currency))
            {
              var par1={ symbol:'', currency:'', price:'' }
              par1.symbol=''+(glovar.CURRENT_PORTFOLIO[j].currency || '').toUpperCase()+(glovar.current_porfolio_ED.currency || '').toUpperCase()+'=X';
              par1.currency=''+(glovar.CURRENT_PORTFOLIO[j].currency || '').toUpperCase();
          
              par1.price='0';
              glovar.GLOBAL_CURRENT_PARTDIVISAS.push(par1)              
              PARDIVISAS_A_PILLAR.push(glovar.CURRENT_PORTFOLIO[j].currency+glovar.current_porfolio_ED.currency+'=X')
            }
          }
         }
       } } catch(Exxx) { /*console.log('petadaaaaaaaaa:'+Exxx)*/}
    }

    //console.log('PARDIVISAS_A_PILLAR:'+PARDIVISAS_A_PILLAR);
    //console.log('glovar.GLOBAL_CURRENT_PARTDIVISAS:'+JSON.stringify(glovar.GLOBAL_CURRENT_PARTDIVISAS));
    //console.log('COTIS_A_PILLAR:'+COTIS_A_PILLAR);






    const { todoCotisPortfolio, isFetchingCotis } = useFetchTodoPortfolio(ARRAY_PORT_COTIS+","+PARDIVISAS_A_PILLAR,0)//"IAG.MC,EURUSD=X")


    if (!isFetchingCotis) 
    {



      

     try 
     {
      for(let i=0; i<todoCotisPortfolio.length; i++) 
      {
       //console.log(todoCotisPortfolio[i].symbol+":"+todoCotisPortfolio[i].regularMarketPrice);
       //###################################################################################//
       for (let j = 0; j < glovar.GLOBAL_CURRENT_PARTDIVISAS.length; j++) 
       {
        //updateamos coti 
        try {
            if(todoCotisPortfolio[i].symbol.length>0 &&
              (glovar.GLOBAL_CURRENT_PARTDIVISAS[j].symbol==todoCotisPortfolio[i].symbol ||
              (glovar.GLOBAL_CURRENT_PARTDIVISAS[j].symbol=="USDEUR=X" && todoCotisPortfolio[i].symbol=="EUR=X"))) {
       
                glovar.GLOBAL_CURRENT_PARTDIVISAS[j].price=todoCotisPortfolio[i].regularMarketPrice;
                //console.log(''+glovar.GLOBAL_CURRENT_PARTDIVISAS[j].symbol+'-'+glovar.GLOBAL_CURRENT_PARTDIVISAS[j].price)
                j=glovar.GLOBAL_CURRENT_PARTDIVISAS.length;
            }
            else {
        //console.log('NO ACTUALIZAMOS DIVI:'+glovar.GLOBAL_CURRENT_PARTDIVISAS[j].symbol)
            }
        }
        catch(exxxc) { /*console.log('PET:'+exxxc)*/}
       }
       //##################################################################################//



  for(let j=0; j<todoPortfolio.length; j++) 
  {
    try { todoPortfolio[j].entrada=todoPortfolio[j].ent; } catch(Exx) {}

   if(todoCotisPortfolio[i].symbol==todoPortfolio[j].symbol) 
   {
    todoPortfolio[j].json=todoCotisPortfolio[i];

    //ahora calculamos los datos.
     if(todoPortfolio[j].sentido==1)  { //alcista
     let benef=((todoCotisPortfolio[i].regularMarketPrice-todoPortfolio[j].entrada)*todoPortfolio[j].acciones)*todoPortfolio[j].multi;
     if(benef) 
     { 
      todoPortfolio[j].benef=benef;
      let rent=benef/(todoPortfolio[j].entrada*todoPortfolio[j].acciones*todoPortfolio[j].multi) 
      if(rent) {todoPortfolio[j].rent=rent*100;}
     }
    }
    else {
     let benef=((todoPortfolio[j].entrada-todoCotisPortfolio[i].regularMarketPrice)*todoPortfolio[j].acciones)*todoPortfolio[j].multi;
     if(benef) 
     { 
      //todoPortfolio[j].benef=benef;
      todoPortfolio[j].benef=benef;
      let rent=benef/(todoPortfolio[j].entrada*todoPortfolio[j].acciones*todoPortfolio[j].multi) 
      if(rent) {todoPortfolio[j].rent=rent*100;} 
     }
    }
    //break;
   }
  }
 }



 //Revisem el balance
 
  //###################################################################################//
  //###################################################################################//
  //###################################################################################//

  try {
    //we have cartera_local and cartera_local_ed (PROFILE), WE CAN REFRESH INFO PROFILE:
    //falta refresh cotis REALES (profits rentabs etc...)
    let app_capitalUsed=0;
    let app_invertido=0;
    let app_currentmoney=0;
    let app_profit=0;
    let app_rentab=0;
    
    
    //carteraInfo.ent=precio;
    //carteraInfo.acciones=numero;
    for (let y = 0; y < glovar.CURRENT_PORTFOLIO.length; y++) 
    {
      //console.log("tengo en cuenta el valor:"+y)
      //Si es la divisa por defecto
      let MULTIPLICADOR_DIVISA=1;
  
      //De momento no tenemos en cuenta el cambio de divisa
      
      //Si no lo es, le aplicamos el multiplicador en función de la divisa por defecto.
      if(glovar.current_porfolio_ED.currency!=glovar.CURRENT_PORTFOLIO[y].currency)
      {
       for (let j = 0; j < glovar.GLOBAL_CURRENT_PARTDIVISAS.length; j++) 
       {
        //updateamos coti 
        try {
         // console.log('length DIVIS:'+glovar.GLOBAL_CURRENT_PARTDIVISAS.length)
        //if(glovar.GLOBAL_CURRENT_PARTDIVISAS[j].currency.toUpperCase()==glovar.CURRENT_PORTFOLIO[y].currency.toUpperCase())
        if(glovar.GLOBAL_CURRENT_PARTDIVISAS[j].currency==glovar.CURRENT_PORTFOLIO[y].currency)
        {
          MULTIPLICADOR_DIVISA= glovar.GLOBAL_CURRENT_PARTDIVISAS[j].price;
          //console.log('ACTIVO:'+glovar.CARTERAS_LOCAL1[i].stock)
          //console.log('DIVISA CARTERA PRINCIPAL:'+glovar.CARTERAS_LOCAL1_ED.currency)
          //console.log('DIVISA Activo cartera:'+glovar.CARTERAS_LOCAL1[i].currency)
          //console.log('glovar.GLOBAL_CURRENT_PARTDIVISAS[j].price:'+glovar.GLOBAL_CURRENT_PARTDIVISAS[j].price)
          j=glovar.GLOBAL_CURRENT_PARTDIVISAS.length;
        }
        }
        catch(Exxx3) {/*console.log("petada multidivisa:"+Exxx3)*/}
       }
      }
  
     
  
      //console.log('ACCIón:'+glovar.CURRENT_PORTFOLIO[y].stock)
      //MULTIPLICADOR_DIVISA=1;
      //console.log('MULTIPLICADOR DIVISA UTILIZADO:'+MULTIPLICADOR_DIVISA)
     let ALCISTA=true;
     try { if(glovar.CURRENT_PORTFOLIO[y].sentido=='2') { ALCISTA=false; } }
     catch(Excep1) {} //1 bulish, 2 bearish, 3 bullish&bearish 
    
    
     app_capitalUsed=app_capitalUsed+
                     (glovar.CURRENT_PORTFOLIO[y].ent*glovar.CURRENT_PORTFOLIO[y].acciones*glovar.CURRENT_PORTFOLIO[y].garantia*MULTIPLICADOR_DIVISA);
   
                    
     if(ALCISTA) 
     { 
      app_currentmoney=//app_currentmoney+(glovar.CURRENT_PORTFOLIO[y].coti*glovar.CURRENT_PORTFOLIO[y].acciones)//*glovar.CURRENT_PORTFOLIO[y].garantia); 
      app_currentmoney+(glovar.CURRENT_PORTFOLIO[y].ent*glovar.CURRENT_PORTFOLIO[y].acciones*glovar.CURRENT_PORTFOLIO[y].garantia*MULTIPLICADOR_DIVISA)+
                           (((glovar.CURRENT_PORTFOLIO[y].coti-glovar.CURRENT_PORTFOLIO[y].ent)*glovar.CURRENT_PORTFOLIO[y].acciones*glovar.CURRENT_PORTFOLIO[y].multi))
                           *MULTIPLICADOR_DIVISA;
     }
     else {
       app_currentmoney=app_currentmoney+(glovar.CURRENT_PORTFOLIO[y].ent*glovar.CURRENT_PORTFOLIO[y].acciones*MULTIPLICADOR_DIVISA)+
                           (((glovar.CURRENT_PORTFOLIO[y].ent-glovar.CURRENT_PORTFOLIO[y].coti)*glovar.CURRENT_PORTFOLIO[y].acciones*glovar.CURRENT_PORTFOLIO[y].multi))
                           *MULTIPLICADOR_DIVISA;
      

          }
    
     //console.log('ANTES CALCULAR APP PROFIT:'+app_profit)
     if(ALCISTA) { app_profit=app_profit+
                (((glovar.CURRENT_PORTFOLIO[y].coti-glovar.CURRENT_PORTFOLIO[y].ent)*glovar.CURRENT_PORTFOLIO[y].acciones*glovar.CURRENT_PORTFOLIO[y].multi))
                *MULTIPLICADOR_DIVISA; }
     else { app_profit=app_profit+
                (((glovar.CURRENT_PORTFOLIO[y].ent-glovar.CURRENT_PORTFOLIO[y].coti)*glovar.CURRENT_PORTFOLIO[y].acciones*glovar.CURRENT_PORTFOLIO[y].multi))
                *MULTIPLICADOR_DIVISA; } 
    
                //console.log('Despues CALCULAR APP PROFIT:'+app_profit)
     try { 
       //if(ALCISTA) { app_rentab=app_rentab+parseFloat(((glovar.CARTERAS_LOCAL1[i].coti-glovar.CARTERAS_LOCAL1[i].ent)/glovar.CARTERAS_LOCAL1[i].ent)*100); /*rent=rent.toFixed(2);*/ }
       //else { { app_rentab=app_rentab+parseFloat(((glovar.CARTERAS_LOCAL1[i].ent-glovar.CARTERAS_LOCAL1[i].coti)/glovar.CARTERAS_LOCAL1[i].ent)*100); /*rent=rent.toFixed(2);*/ } }
       //app_rentab=app_rentab+((app_profit/glovar.CARTERAS_LOCAL1_ED.capital)*100)
     } catch(Exd) {}
    
    
    }
    
    if(app_capitalUsed>0)
    {
    glovar.current_porfolio_ED.capitalUsed=app_capitalUsed;
    glovar.current_porfolio_ED.currentmoney=app_currentmoney;
    let tempInvertido=(app_capitalUsed/glovar.current_porfolio_ED.capital)*100;
    if(glovar.current_porfolio_ED.invertido!=tempInvertido) {
      //console.log("DEBEMOS ACTUALIZAR INVERTIDO!!!");
      //console.log("glovar.current_porfolio_ED.invertido:"+glovar.current_porfolio_ED.invertido);
      //console.log("tempInvertido:"+tempInvertido);
      glovar.current_porfolio_ED.invertido=(app_capitalUsed/glovar.current_porfolio_ED.capital)*100;
      setCurrent_porfolio_ED(glovar.current_porfolio_ED);

      if(1==1) {
        try 
             {
              var mode=5; //solo invertido.
              var MODE_FECTH=""+mode;
   
              var body_content=JSON.stringify(glovar.current_porfolio_ED);

              let headers = new Headers(); 
              headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
              //console.log(" 1 UPDATEO SERVER:");
              headers.set('Parameters', 'Basic ' + btoa(MODE_FECTH+ "%?z&" +glovar.USER_USERNAME));
          
              
     
              const response = fetch(glovar.URL_BACKEND_PROD+'/api/updatesuserbody',{  //mode: 'no-cors',//fetch(glovar.URL_BACKEND_PROD+'/api/users', {
                    //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                    //method: "POST",
                    method:'POST',
                    headers: headers,
                    body:body_content//JSON.stringify(cleanedPortfolio)
                    /*body: 
                    JSON.stringify({ 
                      name: glovar.USER_USERNAME,
                      body:body_content
                      //invertido:glovar.current_porfolio_ED.invertido,
                    
                   })*/
              }) 
     
              //console.log("pujat correctament dades user OK1 ");  //+glovar.CURRENT_USER.name)
             }
             catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
      }

      
      if(1==1) {
        try 
             {
              var headers = new Headers(); 
     
              //console.log("glovar.USER_API_POST:"+glovar.USER_API_POST)
              //console.log("glovar.PASS_API_POST:"+glovar.PASS_API_POST)
     
              headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
              headers.set('Content-Type', 'application/json');
          
              
     
              const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
                    //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                    //method: "POST",
                    method: "PUT",  
                    headers: headers,
                   
                    body: 
                    JSON.stringify({ 
                      name: glovar.USER_USERNAME,
                     invertido:glovar.current_porfolio_ED.invertido,
                    
                   })
              }) 
     
              //console.log("pujat correctament dades user OK1 ");  //+glovar.CURRENT_USER.name)
             }
             catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
      }


    }
    glovar.current_porfolio_ED.invertido=(app_capitalUsed/glovar.current_porfolio_ED.capital)*100;
    glovar.current_porfolio_ED.profit=app_profit;
    glovar.current_porfolio_ED.rentab=((app_profit/glovar.current_porfolio_ED.capital)*100);
    
    }
    else 
    {
    glovar.current_porfolio_ED.capitalUsed=0;
    glovar.current_porfolio_ED.currentmoney=0;
    glovar.current_porfolio_ED.invertido=0;
    glovar.current_porfolio_ED.profit=app_profit;
    if(glovar.current_porfolio_ED.capital>0) {
    glovar.current_porfolio_ED.rentab=((app_profit/glovar.current_porfolio_ED.capital)*100);
    }
    }
    }
    catch(Expre) { /*console.log('ERROR REFRESHING PROFILE:'+Expre)*/}
  
    //console.log('glovar.current_porfolio_ED.capitalUsed:'+glovar.current_porfolio_ED.capitalUsed)
    //console.log('glovar.current_porfolio_ED.currentmoney:'+glovar.current_porfolio_ED.currentmoney)
    
    //###################################################################################//
    //###################################################################################//
    //###################################################################################//

}
catch(exxx) {}

       try {  if(!glovar.REFRESH_FRONTUSER) 
        { eventBus.dispatch("showRefreshHomefront", { }); } } catch(exx) {}

        if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
          {
            return (
        
        
              todoPortfolio.map((d, i) => (
                  <PortfolioListItem key={d.id} Toptrader={d} index={i} screen_id={4} mobilever={1} hidetit={false}></PortfolioListItem>
      
              ))
           
         
        );
          }
          else {
      return (
        
        
            todoPortfolio.map((d, i) => (
                <PortfolioListItem key={d.id} Toptrader={d} index={i} screen_id={4}></PortfolioListItem>
    
            ))
         
       
      );}
      }

    //const  todoTopTraders = useFetchTodoToptraders()
}
