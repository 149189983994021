import React, { useState } from 'react';
import './CookieConsentPopup.css';
import glovar from './glovar';
import CookiePolicy from './CookiePolicy';
import SearchAssetFront from './SearchAssetFront';
import ShowAssetsSummary from './ShowAssetsSummary';
import ShowPopNewsletter from './ShowPopNewsletter';
import HeaderMenu from './HeaderMenu';
import FooterMenu from './FooterMenu';
import { Image } from "@chakra-ui/image";
import eventBus from "../eventBus";
import { SimpleGrid, GridItem, Text, Box, Stack, VStack, HStack,  Spacer, Flex } from "@chakra-ui/layout";


const  ShowDataSearch = ({ textType}) => {

  const ItemSibedar = ({ icon, active, id }) => (

      
      
    <Box
      _hover={{ cursor: "pointer" }}
      p={4}
      borderRadius="md"
      bg={(glovar.CURRENT_MENU_ID==id) ? "#000000" : "lightgray"}
      textColor={(glovar.CURRENT_MENU_ID==id) ? "white" : "gray"}
      onClick={() => {
        try {
          // EventGA("click_element","chgMenu_"+glovar.CURRENT_MENU_ID+"to "+id, id);  
        }
          catch (exx) { /*console.log("petada event:"+exx)*/}

        //this.changemenu(id)
        try {
          eventBus.dispatch("gotoChangeMenu", { id });
        }
        catch(Exx) {}

      }}
    >
      <Image src={icon} w={5}></Image>
    </Box>
  );


   { // terms
      if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        
      return (
        <div>
      
        <HeaderMenu showSearch={true}/>
     
     <SimpleGrid columns={14} gap={0} p={0}  backgroundColor="#F8F8F8">
        
         <GridItem colSpan={14} mr={0} backgroundColor="#F8F8F8" >
    
        <SearchAssetFront noLanding={1}/>
        <ShowAssetsSummary noLanding={1}/>
        <Box marginBottom={40} marginTop={0} backgroundColor="#F8F8F8">
        <ShowPopNewsletter fitxaDesk={1} forceBackground={1}/>
        </Box>
        </GridItem>
        <GridItem colSpan={14} mr={0} backgroundColor="#F8F8F8" >
        <FooterMenu/>
        </GridItem>
        </SimpleGrid>
        
        </div>
      );
      } 
      else {
        return (
          <div>
      
      <HeaderMenu showSearch={true}/>
   
   <SimpleGrid columns={14} gap={1} p={2}  backgroundColor="#F8F8F8">
       <GridItem colSpan={1}>
       <Box mt={4}>
       <VStack>
         <ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_briefcase_white_48dp.png`} id={201} />
         {1==1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_compass_white_48dp.png`} id={13} />}
         {1==1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_data_search_48dp.png`} id={14} />}
         {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/baseline_notifications_white_48.png`} id={12} />}
         {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_podium_white_24dp.png`} id={202} />}
         {glovar.MODE_USER>=1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/baseline_account_box_white_48.png`} id={3} />}
        
         {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_chart_areaspline_white_24dp.png`} id={4} />}
       </VStack>
     </Box>
         </GridItem>
       <GridItem colSpan={13} mr={6} backgroundColor="#F8F8F8" >
  
      <SearchAssetFront noLanding={1}/>
      <ShowAssetsSummary noLanding={1}/>
      <Box marginBottom={40} marginTop={0} backgroundColor="#F8F8F8">
      <ShowPopNewsletter fitxaDesk={1} forceBackground={1}/>
      </Box>
      </GridItem>
      </SimpleGrid>
      <FooterMenu/>
      </div>
        );
      }
      }

  }
  
  export default ShowDataSearch;