import { Text, Spacer, Heading, Flex, Box } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import React, { useState, useCallback } from "react";
import eventBus from "../eventBus";
import glovar from './glovar'

// Funciones para manejar eventos
function showElement(element) {
  eventBus.dispatch("showElement", { el: element });
}

function showOptions(pos) {
  eventBus.dispatch("showPortfolioOptions", { pos: (pos - 1) });
}

function showEditPos(pos) {
  eventBus.dispatch("showEditPosPortfolio", { pos: pos });
}

function showClosePos(pos) {
  eventBus.dispatch("showClosePosPortfolio", { pos: pos });
}

// El componente individual de la lista
const PortfolioListItem = ({ Toptrader, index, grayScale, hidetit, mobilever, screen_id }) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  //console.log("=============")
  //console.log(JSON.stringify(Toptrader))

  //Adaptamos formato TopTrader al JSON obtenido
  try {
    if(Toptrader.json.regularMarketPrice) {
      Toptrader.coti=Toptrader.json.regularMarketPrice;
    }
  }
  catch(Ex) {

  }
  

  //Temporalmente forzamos que se vea bien, en busca de un efecto más suave.
  if(glovar.NO_GRAYSCALE_NOPUBLIC==1) {
   grayScale=false;
  }

  try { if(Toptrader.stock) {Toptrader.title=Toptrader.stock} } catch(Exxx) {}

  // Esta función alterna el estado de las opciones
  const handleBoxClick = useCallback(() => {
    if (screen_id === 3) {
      setIsOptionsVisible(prev => !prev);
    } else {
      showElement(Toptrader.json);
    }
  }, [screen_id, Toptrader.json]);

  // Definir colores basados en beneficios
  let color1 = Toptrader.benef > 0 ? "#1FB31F" : "#D61A18";
  let color2 = "#FFFFFF";
  let colorTitle = "#000000";
  let colorText = "black";

  if (grayScale) {
    color1 = "#CCCCCC";
    colorTitle = "#CCCCCC";
    colorText = "gray.100";
  }

  // Verificamos que los valores estén correctamente definidos
  const benef = hidetit ? "" : (Toptrader.benef?.toFixed(0) || 0);
  const rent = Toptrader.rent?.toFixed(2) || 0;
  const simbol = rent >= 0 ? "+" : " ";
  const toptraderTit = hidetit ? "" : Toptrader.acciones;

  return (
    <Box mr={0} mt={0} mb={0} color={colorText} _hover={{ cursor: "pointer" }} onClick={handleBoxClick}>
      <Flex alignItems="center" ml={0} mr={2} mb={3}>
        {/* Título del activo y fecha */}
        <Box ml={0} w={"13pc"}>
          <Heading fontSize={mobilever ? "sm" : "md"} w={mobilever ? "8pc" : "10pc"}>
            {Toptrader.title || "Activo Desconocido"}
          </Heading>
          <Text fontSize="13" fontWeight="thin">{Toptrader.fent}</Text>
        </Box>

        {/* Acciones */}
        {(1==1)/*!hidetit*/&& (
          <Box borderRadius="lg" fontSize="12" fontWeight="semibold" textAlign="center" mr={4} w={"4pc"} p={1} bg={colorTitle} textColor="#FFFFFF">
            {Toptrader.sentido == 2 ? "-" + toptraderTit : "+" + toptraderTit}
          </Box>
        )}

        {/* Entrada y cotización */}
        <Box ml={0} w={"6pc"}>
          <Text fontSize="15" textAlign="center" fontWeight="normal">
            {Toptrader.entrada}{Toptrader.m}
          </Text>
          {mobilever && (
            <Text fontSize="15" textAlign="center" fontWeight="bold">
              {Toptrader.coti}{Toptrader.m}
            </Text>
          )}
        </Box>

        {/* Cotización */}
        {!mobilever && (
          <Box ml={0} w={"7pc"}>
            <Text fontSize="15" textAlign="center" fontWeight="bold">
              {Toptrader.coti}{Toptrader.m}
            </Text>
          </Box>
        )}

        <Spacer />

        {/* Objetivo y Stop */}
        {!mobilever && (
          <Box textAlign={"center"} mr={0} w={"10pc"}>
            <Text fontSize="14" fontWeight="normal">
              {Toptrader.obj}{Toptrader.m} - {Toptrader.stop}{Toptrader.m}
            </Text>
          </Box>
        )}
        {mobilever && (
          <Box textAlign={"center"} mr={0} w={"10pc"}>
            <Text fontSize="14" fontWeight="normal">{Toptrader.obj}{Toptrader.m}</Text>
            <Text fontSize="14" fontWeight="normal">{Toptrader.stop}{Toptrader.m}</Text>
          </Box>
        )}

        <Spacer />

        {/* Beneficio y Rentabilidad */}
        <Box borderRadius="lg" fontSize="14" fontWeight="semibold" textAlign="center" w={"10.5pc"} minW={"5.5pc"} p={1} ml={0} bg={color1} textColor={color2}>
          {simbol}{benef}{Toptrader.m}     {simbol}{rent}%
        </Box>
      </Flex>

      {/* Opciones de edición y cierre */}
      {isOptionsVisible && (
        <Flex alignItems="center">
          {!glovar.APP_STATE_RUNNING && (
            <>
              <Box _hover={{ cursor: "pointer" }} p={1.5} m={1} ml={0} borderRadius="md" bg="gray" textColor="white" onClick={() => showEditPos(index)}>
                <Image src={`${process.env.PUBLIC_URL}/assets/ic_pencil_white_24dp.png`} w={6}></Image>
              </Box>
              <Box _hover={{ cursor: "pointer" }} p={1.5} m={1} ml={2} borderRadius="md" bg="gray" textColor="white" onClick={() => showClosePos(index)}>
                <Image src={`${process.env.PUBLIC_URL}/assets/ic_exit_to_app_white_24dp.png`} w={6}></Image>
              </Box>
              <Box _hover={{ cursor: "pointer" }} p={1.5} m={1} ml={2} borderRadius="md" bg="gray" textColor="white" onClick={() => showElement(Toptrader.json)}>
                <Image src={`${process.env.PUBLIC_URL}/assets/ic_chart_areaspline_white_24dp.png`} w={6}></Image>
              </Box>
            </>
          )}

          {glovar.APP_STATE_RUNNING && (
            <Text fontSize="0.9em" fontWeight="normal" ml="1pc" textAlign="center">
              INFO: Para poder editar tu portfolio es necesario que salgas de la APP.
            </Text>
          )}
        </Flex>
      )}
    </Box>
  );
};

// Función de comparación personalizada para React.memo
// Esto asegura que el componente solo se re-renderiza si cambian las props
function areEqual(prevProps, nextProps) {
  return (
    prevProps.Toptrader === nextProps.Toptrader &&
    prevProps.index === nextProps.index &&
    prevProps.grayScale === nextProps.grayScale &&
    prevProps.hidetit === nextProps.hidetit &&
    prevProps.mobilever === nextProps.mobilever &&
    prevProps.screen_id === nextProps.screen_id
  );
}

// Envolver el componente en React.memo con la función de comparación
export default React.memo(PortfolioListItem, areEqual);
