import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './forms.css'
//import {signInWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
//import {auth} from './firebase'
import {useNavigate} from 'react-router-dom'
import {useAuthValue} from './AuthContext'

import { IconButton } from "@chakra-ui/button";
import { SimpleGrid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Grid } from "@chakra-ui/layout";
import { Center } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
//import { Button } from "@chakra-ui/layout";
import { FiPlus } from "react-icons/fi";
import { HStack } from "@chakra-ui/layout";
import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";

//import glovar from './components/glovar';
import glovar from './components/content/glovar';

import { Spacer } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import eventBus from "./components/eventBus";

import "./App.css";
import { getToken, removeUserSession, setUserSession, setPortfolio, setCurrent_porfolio_ED } from './utils/Common';
import useFetchUpdatesUser from './components/utils/useFetchUpdatesUser';

import { 
  updateCarteraSERVER,updateFCHuserSERVER
   } from './utils/Common';



//useFetchUpdatesUser = (MODE_USER_FECTH,favoritos,cartera,historial)

//import { connectFirestoreEmulator } from 'firebase/firestore'
//import {db} from './firebase'

//import { doc, getDoc, updateDoc } from "firebase/firestore";

function cancelar() {
  //glovar.CURRENT_MENU_ID=3;
  //console.log("canceloooooooooooooo")
  eventBus.dispatch("hideEditPosPortfolio", { pos: 0 });
  //console.log("despues del eventBus")
  //console.log('commmm pos:'+pos)
  } 


  

 
var edit_entrada=45.57;

var edit_titulos=45.57; 
var edit_objetivo=45.57;
var edit_stoploss=45.57;
var edit_precio=45.57;
var edit_garantia=45.57;
var edit_multi=45.57;
var edit_sentido=1;
var edit_stock=0;

var edit_fent="";
var edit_temp=0;
var edit_symbol="";
var edit_currency="";
var edit_m="";


var offTRADERPOS, offEDITPOS=0;

//function EditPosTrader({ traderPos }) {

  //function EditPosTrader() {

    async function  getRadar() {
      //console.log("holaaa sunccccccccc:"+offTRADERPOS)
      //console.log("yeeeeeeeeeee")
      updateCarteraSERVER();
      setPortfolio(glovar.CURRENT_PORTFOLIO);

      glovar.current_porfolio_ED.cartera = [...glovar.CURRENT_PORTFOLIO];
      //glovar.current_porfolio_ED.historial = [...glovar.CURRENT_HISTORIAL];
      setCurrent_porfolio_ED(glovar.current_porfolio_ED);
      updateFCHuserSERVER(5); //Cartera_ed

  if(1==1) {
    try 
         {
          var headers = new Headers(); 
 
          //console.log("glovar.USER_API_POST:"+glovar.USER_API_POST)
          //console.log("glovar.PASS_API_POST:"+glovar.PASS_API_POST)
 
          headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
          headers.set('Content-Type', 'application/json');
      
          //glovar.CURRENT_USER=cartera;
          //glovar.current_porfolio_ED=cartera.cartera_ed;
                       
           //console.log(JSON.stringify(headers))
 
           var carteraPARSED=JSON.stringify(glovar.CURRENT_PORTFOLIO);
           carteraPARSED = carteraPARSED.replace('[', '');
           carteraPARSED = carteraPARSED.replace(']', '');
 
           /*
           var historialPARSED=JSON.stringify(glovar.CURRENT_HISTORIAL);
           historialPARSED = historialPARSED.replace('[', '');
           historialPARSED = historialPARSED.replace(']', '');
           */
 
           /*
           var favoritosPARSED=JSON.stringify(glovar.CURRENT_FAVORITOS);
           favoritosPARSED = favoritosPARSED.replace('[', '');
           favoritosPARSED = favoritosPARSED.replace(']', '');
           */
 
          const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
                //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                //method: "POST",
                method: "PUT",  
                headers: headers,
               
                body: 
                JSON.stringify({ 
                 //rentab: glovar.current_porfolio_ED.rentab,
                 name: glovar.USER_USERNAME,//glovar.CURRENT_USER.name,
                 //idCartera:  glovar.USER_USERNAME, //glovar.CURRENT_USER.idCartera,
                 //id:  0,//glovar.USER_USERNAME,//glovar.CURRENT_USER.name,//glovar.TOPTRADERS_PRO1[j].id,
                 /*descript: glovar.current_porfolio_ED.descript,//glovar.TOPTRADERS_PRO1[j].descript,
                 risk_level: glovar.current_porfolio_ED.risk_level,
                 max_level: glovar.current_porfolio_ED.max_level,
                 countriesID:glovar.current_porfolio_ED.countriesID,
                 activos: glovar.current_porfolio_ED.activos,
                 */
                 op_cerradas: glovar.current_porfolio_ED.op_cerradas,
                 rentmedia_op:glovar.current_porfolio_ED.rentmedia_op,
                 //sentido: glovar.current_porfolio_ED.sentido,
                 //temporal: glovar.current_porfolio_ED.temporal,
                 //historial: historialPARSED,//JSON.stringify(glovar.CURRENT_HISTORIAL),
                 cartera: carteraPARSED,//JSON.stringify(glovar.CURRENT_PORTFOLIO),//glovar.TOPTRADERS_PRO1[j].cartera_text,
                 //favoritos: favoritosPARSED,
                 //cartera_text:glovar.TOPTRADERS_PRO1[j].cartera_text,
                 //cartera:glovar.TOPTRADERS_PRO1[j].cartera,
                 //capital:glovar.current_porfolio_ED.capital,
                 //invertido:glovar.current_porfolio_ED.invertido,
                 //moneda:glovar.current_porfolio_ED.moneda,
                 //profit:glovar.current_porfolio_ED.profit,
                 //currency:glovar.current_porfolio_ED.currency,
                 //priority:glovar.current_porfolio_ED.priority,
                 //rentab_acum:glovar.current_porfolio_ED.rentab_acum,
                 //profit_acum:glovar.current_porfolio_ED.profit_acum,

                 lastDataUpdateCartera_ed:glovar.current_porfolio_ED.lastDataUpdateCartera_ed, 
                 //lastDataUpdateFavoritos:glovar.current_porfolio_ED.lastDataUpdateFavoritos, 
                 //lastDataUpdateHistorial:glovar.current_porfolio_ED.lastDataUpdateHistorial,
                 lastDataUpdatePortfolio:glovar.current_porfolio_ED.lastDataUpdatePortfolio,
                 /*
                 type_cartera:"0",//glovar.TOPTRADERS_PRO1[j].type_cartera, //por defecto solo la pueden ver los premiums!
                 op_win:"0",// glovar.TOPTRADERS_PRO1[j].op_win,
                 followers:"0",// glovar.TOPTRADERS_PRO1[j].followers,
                 cap_inicial:"0",//glovar.TOPTRADERS_PRO1[j].cap_inicial,
                 ranking:"0",//glovar.TOPTRADERS_PRO1[j].ranking,
                 FOLLOWERS_LIST:"0"//:glovar.TOPTRADERS_PRO1[j].FOLLOWERS_LIST
                 */
            //id:"1",//glovar.TOPTRADERS_PRO1[j].id,
            //descript: "que pasa nen me voy de subidon!"
               })
          }) 
 
          //console.log("pujat correctament dades user OK1 ");  //+glovar.CURRENT_USER.name)
         }
         catch(Exx) {/*console.log("PETADA pujat dades user: "+Exx)*/ }
       }
      //console.log("despues updateCarteraServer!")
      //useFetchUpdatesUser = (MODE_USER_FECTH,favoritos,cartera,historial)
      //useFetchUpdatesUser = (2,null,glovar.CURRENT_PORTFOLIO,null);

      /*
      async function  getRadar() {
        console.log("UPTADE PORTFOLIO!!!");
        console.log("yeeeeeeeeeee")
  
        try 
            {
              console.log("updateUserPortfolio!!!!!")
              
             var headers = new Headers(); 
    
             
    
             headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
             headers.set('Content-Type', 'application/json');
         
    
             const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
                   //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                   //method: "POST",
                   method: "PUT",  
                   headers: headers,
                  
                   body: 
                   JSON.stringify({ 
                    name: glovar.USER_USERNAME,//glovar.CURRENT_USER.name, (es el id del user)
                    cartera:glovar.CURRENT_PORTFOLIO,
                    lastDataUpdatePortfolio:timemilis
                  })
             }) 
    
             //console.log("pujat correctament updateUserPortfolioOptions ");  //+glovar.CURRENT_USER.name)
             
            }
            catch(Exx) {  }
            
       }
       */  
      
/*

const timemilis=Math.floor(Date.now()/1);

//const washingtonRef = doc(db, "users_anom", "1648970897074");
  const washingtonRef = doc(db, "users", glovar.USER_USERNAME);


  console.log("antes del doc")
  const docUser = await getDoc(washingtonRef);
  console.log("ya tengo el doc!!!")

 
  try { glovar.APP_STATE_STATE=docUser.data().appState; } catch(Exxx) { glovar.APP_STATE_STATE="none"; }
  try { if(glovar.APP_STATE_STATE==glovar.APP_STATE_ACTIVE) {  glovar.APP_STATE_RUNNING=true; } } catch(Exxxs) {} 


  if(!glovar.APP_STATE_RUNNING)
  {
await updateDoc(washingtonRef, {
  cartera:glovar.CURRENT_PORTFOLIO,
  lastDataUpdatePortfolio:timemilis
 });


      eventBus.dispatch("hideEditPosPortfolio", { pos: 0 });
}

else 
*/
{
  //console.log("SAL DE LA APPP!!!!!")
   eventBus.dispatch("hideEditPosPortfolio", { pos: 0 });
  // console.log("despues del dispatch!!!!!!!!!!!!!!!!")
}
    }


    function updateUserPortfolio() {
      try 
          {
            //console.log("updateUserPortfolio!!!!!")
            /*
           var headers = new Headers(); 
  
           
  
           headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
           headers.set('Content-Type', 'application/json');
       
  
           const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
                 //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                 //method: "POST",
                 method: "PUT",  
                 headers: headers,
                
                 body: 
                 JSON.stringify({ 
                  name: glovar.USER_USERNAME,//glovar.CURRENT_USER.name, (es el id del user)
                  SHOW_PUBLIC_PROFILE_USER: glovar.SHOW_PUBLIC_PROFILE_USER,
                  SHOW_PUBLIC_PROFILE_USER_INFO: glovar.SHOW_PUBLIC_PROFILE_USER_INFO,
                  SHOW_PUBLIC_PROFILE_BTNFOLLOW: glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW,
                  SHOW_PUBLIC_CARTERA: glovar.SHOW_PUBLIC_CARTERA,
                  SHOW_PUBLIC_CARTERA_TIT_BENEF: glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF,
                  SHOW_PUBLIC_CARTERA_ONLY_FOLLOW: glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW,
                  SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW: glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW,
                  SHOW_PUBLIC_HISTORIAL: glovar.SHOW_PUBLIC_HISTORIAL,
                  SHOW_PUBLIC_HISTORIAL_TIT_BENEF: glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF,
                  //NUM_FOLLOWERS: user.NUM_FOLLOWERS,
                  //NUM_VISITAS: user.NUM_VISITAS,
                  //NUM_LIKES: user.NUM_LIKES
                })
           }) 
  
           //console.log("pujat correctament updateUserPortfolioOptions ");  //+glovar.CURRENT_USER.name)
           */
          }
          catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
          
     }


  export default ({ traderPos, editPos, cont }) => {

  //editcont=cont;

  //console.log('traderPos:'+traderPos)
  //console.log('editPos:'+editPos)

  offTRADERPOS=traderPos;
  offEDITPOS=editPos;


  //console.log("cacaton23: "+JSON.stringify(glovar.CURRENT_PORTFOLIO[editPos]));
  //editPos=0;
  //console.log("cacaton23: "+JSON.stringify(glovar.CURRENT_PORTFOLIO[editPos]));



  //{"CarteraID":"0","opID":"100","stockID":"1000","stock":"Allianz","Benf":"456.30","rent":"4.95","ent":"205","fent":"19-07-21","temp":"1604422800",
  //"obj":"150","stop":"210","idPOS":"0","acciones":"45","status":"0","sal":"90","fsal":"23-4-2020","tempsal":"1596992669",
  //"coti":194.86,"sentido":"2","garantia":"1","multi":"1","currency":"EUR","m":"€","symbol":"ALV.DE"}

  edit_entrada=glovar.CURRENT_PORTFOLIO[editPos].ent;

 edit_titulos=glovar.CURRENT_PORTFOLIO[editPos].acciones; 
 edit_objetivo=glovar.CURRENT_PORTFOLIO[editPos].obj; 
 edit_stoploss=glovar.CURRENT_PORTFOLIO[editPos].stop; 
 edit_precio=glovar.CURRENT_PORTFOLIO[editPos].coti; 
 edit_garantia=glovar.CURRENT_PORTFOLIO[editPos].garantia;
 edit_multi=glovar.CURRENT_PORTFOLIO[editPos].multi;
 edit_sentido=glovar.CURRENT_PORTFOLIO[editPos].sentido;
 edit_stock=glovar.CURRENT_PORTFOLIO[editPos].stock;

 var edit_fent=glovar.CURRENT_PORTFOLIO[editPos].fent; 
 var edit_temp=glovar.CURRENT_PORTFOLIO[editPos].temp; 
 var edit_symbol=glovar.CURRENT_PORTFOLIO[editPos].symbol; 
 var edit_currency=glovar.CURRENT_PORTFOLIO[editPos].currency; 
 var edit_m=glovar.CURRENT_PORTFOLIO[editPos].m; 


 //console.log('edit_entrada:'+edit_entrada);

 
 
  const [entrada, setEntrada] = useState(edit_entrada)
  const [titulos, setTitulos] = useState(edit_titulos) 
  const [objetivo, setObjetivo] = useState(edit_objetivo)
  const [stoploss, setStoploss] = useState(edit_stoploss) 
  const [precio, setPrecio] = useState(edit_precio)
  const [garantia, setGarantia] = useState(edit_garantia) 
  const [multi, setMulti] = useState(edit_multi)
  const [sentido, setSentido] = useState(edit_sentido) 
  const [stock, setStock] = useState(edit_stock) 

  const [fent, setFent] = useState(edit_fent)
  const [temp, setTemp] = useState(edit_temp)
  const [symbol, setSymbol] = useState(edit_symbol)
  const [currency, setCurrency] = useState(edit_currency)
  const [m, setM] = useState(edit_m)

  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  //const {setTimeActive} = useAuthValue()
  //const navigate = useNavigate()

  /*useEffect(() => {
    //if(glovar.FORCE_REFRESH_ELEMENT==1) 
    {
    setEntrada(edit_entrada); // This will always use latest value of count
    }
}, [edit_entrada]);*/

  useEffect(() => { setEntrada(edit_entrada); }, [edit_entrada]);
  useEffect(() => { setTitulos(edit_titulos); }, [edit_titulos]);
  useEffect(() => { setObjetivo(edit_objetivo); }, [edit_objetivo]);
  useEffect(() => { setStoploss(edit_stoploss); }, [edit_stoploss]);
  useEffect(() => { setPrecio(edit_precio); }, [edit_precio]);
  useEffect(() => { setGarantia(edit_garantia); }, [edit_garantia]);
  useEffect(() => { setMulti(edit_multi); }, [edit_multi]);
  useEffect(() => { setSentido(edit_sentido); }, [edit_sentido]);
  useEffect(() => { setStock(edit_stock); }, [edit_stock]);


  useEffect(() => { setFent(edit_fent); }, [edit_fent]);
  useEffect(() => { setTemp(edit_temp); }, [edit_temp]);
    useEffect(() => { setSymbol(edit_symbol); }, [edit_symbol]);
  useEffect(() => { setCurrency(edit_currency); }, [edit_currency]);
    useEffect(() => {  setM(edit_m); }, [edit_m]);




  //console.log('entrada:'+entrada)

  // Crea un estado para manejar el perfil mostrado en el portfolio
  const [showMyProfile, setShowMyProfile] = useState(glovar.SHOW_MY_PROFILE);

  const login = e => {
    //const login = async (e) => {
      
    //e.preventDefault()
    

    /*
    console.log("hooaaaa")
    console.log('entrada:'+entrada);
    console.log('titulos:'+titulos);
    console.log('objetivo:'+objetivo);
    console.log('stoploss:'+stoploss);
    console.log('precio:'+precio);
    console.log('garantia:'+garantia);
    console.log('multi:'+multi);
    console.log('sentido:'+sentido);
    console.log("adiosss")
    */
    
    glovar.CURRENT_PORTFOLIO[offEDITPOS].acciones=titulos; 

    glovar.CURRENT_PORTFOLIO[offEDITPOS].ent=entrada;
    glovar.CURRENT_PORTFOLIO[offEDITPOS].entrada=entrada;

    glovar.CURRENT_PORTFOLIO[offEDITPOS].obj=objetivo; 
    glovar.CURRENT_PORTFOLIO[offEDITPOS].stop=stoploss; 
    glovar.CURRENT_PORTFOLIO[offEDITPOS].coti=precio; 
    glovar.CURRENT_PORTFOLIO[offEDITPOS].garantia=garantia;
    glovar.CURRENT_PORTFOLIO[offEDITPOS].multi=multi;
    glovar.CURRENT_PORTFOLIO[offEDITPOS].sentido=sentido;

    glovar.CURRENT_PORTFOLIO[editPos].stock=stock;

    glovar.CURRENT_PORTFOLIO[editPos].fent=fent;
    glovar.CURRENT_PORTFOLIO[editPos].temp=temp;
    glovar.CURRENT_PORTFOLIO[editPos].symbol=symbol;
    glovar.CURRENT_PORTFOLIO[editPos].currency=currency;
    glovar.CURRENT_PORTFOLIO[editPos].m=m;

    //glovar.SHOW_MY_PROFILE.cartera=glovar.CURRENT_PORTFOLIO;
     // Actualiza SHOW_MY_PROFILE.cartera con el nuevo CURRENT_PORTFOLIO
     glovar.SHOW_MY_PROFILE.cartera = [...glovar.CURRENT_PORTFOLIO];

     // Forzar actualización del componente asignando el estado
     //setShowMyProfile({ ...glovar.SHOW_MY_PROFILE });


    //console.log("glovar.SHOW_MY_PROFILE:"+JSON.stringify(glovar.SHOW_MY_PROFILE.cartera[0]));
    
    //console.log("Entrada nueva1:"+glovar.CURRENT_PORTFOLIO[offEDITPOS].ent);
    //console.log("Entrada nueva2:"+glovar.SHOW_MY_PROFILE.cartera[offEDITPOS].ent);
    
    getRadar(offTRADERPOS);

    offTRADERPOS=traderPos;
    offEDITPOS=editPos;

    //glovar.CURRENT_PORTFOLIO.splice(offEDITPOS, 1);
    
    //glovar.SHOW_MY_PROFILE.cartera=glovar.CURRENT_PORTFOLIO;
     // Actualiza SHOW_MY_PROFILE.cartera con el nuevo CURRENT_PORTFOLIO
     //glovar.SHOW_MY_PROFILE.cartera = [...glovar.CURRENT_PORTFOLIO];

    //var CARTERA_INFO_CARTERA_TEXT='['+glovar.TOPTRADERS_PRO1[offTRADERPOS].cartera_text+']';
    //console.log('##CCARTERA_INFO_CARTERA_TEXT:'+CARTERA_INFO_CARTERA_TEXT);
     
    //var CARTERA_INFO_CARTERA = JSON.parse(CARTERA_INFO_CARTERA_TEXT);

    //glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].acciones=titulos; 
    /*
 edit_objetivo=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].obj; 
 edit_stoploss=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].stop; 
 edit_precio=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].coti; 
 edit_garantia=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].garantia;
 edit_multi=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].multi;
 edit_sentido=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].sentido;
 edit_stock=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].stock;

 */

 


/*
 const washingtonRef = doc(db, "toptraders", "Eva_trading");
 
 async () => {
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  cartera:glovar.TOPTRADERS_PRO1[data.pos].cartera_text//glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA
 });
 }
 */
 /*
 var washingtonRef = db.collection("toptraders").doc("Eva_trading");

 const cityRef = db.collection('cities').doc('DC');

// Set the 'capital' field of the city
const res = await cityRef.update({capital: true});*/

 /*

// Set the "capital" field of the city 'DC'
return washingtonRef.update({
  cartera:glovar.TOPTRADERS_PRO1[data.pos].cartera_text//glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA
})
.then(() => {
    console.log("Document successfully updated!");
})
.catch((error) => {
    // The document probably doesn't exist.
    console.error("Error updating document: ", error);
});
*/

    /*
    try {
      var db = firebase.firestore();
   
      let NOM_USER_FB=glovar.USER_USERNAME;
      let COLLECTION_FB="users";
      
      //if(glovar.MODE_USER==0) {NOM_USER_FB=glovar.ANOM_USER_USERNAME; COLLECTION_FB='users_anom';} 
      
      db.collection("toptraders").doc("Eva_trading").update({
        //historial: glovar.HISTORIAL_LOCAL1,
        cartera:glovar.TOPTRADERS_PRO1[data.pos].cartera_text//glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA
        //numFavoritos: glovar.NUM_FAVORITOS
    })
    .catch(function(error) {
      //console.log('ERROR UPLOAD CARTERA SERVER:'+error);  
    }).then(function() {
      //console.log("###UUPLOAD CARTERA SERVER");
      }
     );
    }
    catch(exxx) {
      //console.log('Petada ERROR UPLOAD carera SERVER:'+exxx);  
    }
    */
   
    
    //glovar.CURRENT_MENU_ID=3;
    //eventBus.dispatch("hideEditPosTrader", { pos: 0 });
    //console.log('commmm pos:'+pos)
    } 
  

  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
   {
   return (
    <SimpleGrid columns={14} gap={0} p={0}>
     
       <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={0}> 
 
    
 <HStack marginBottom="1.2pc" justifyContent="space-between" mt={4}>
         <div style={{ width: "100%",  textAlign:"center" }}>
           <input
            style={{  width: "80%", borderRadius: '4px', marginRight:"5%", border: '2px solid lightgray', textAlign:"center" }}
             type="text"
             id="entrada"
             placeholder={stock}
             name="entrada"
             value={stock}
             //required
             onChange={e => setStock(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>
 </HStack>
 
 <HStack marginBottom="1.2pc" justifyContent="space-between">
         <div style={{ width: "7.5pc", marginRight:"10px", textAlign:"center",  }}>
           <label className="formFieldLabel" htmlFor="email">
             Entrada
           </label>
           <input
            style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
             type="number"
             id="entrada"
             placeholder={edit_entrada}
             name="entrada"
             value={entrada}
             //required
             onChange={e => setEntrada(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>
        
        
 
         <div className="formField"    style={{ width: "7.5pc", marginLeft:"5px",marginRight:"5px", textAlign:"center" }}>
           <label className="formFieldLabel" htmlFor="email">
             Títulos
           </label>
           <input
           style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
             type="text"
             id="titulos"
             placeholder={edit_titulos}
             name="titulos"
             value={titulos}
             //required
             onChange={e => setTitulos(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>
 
         <div className="formField" style={{ width: "7.5pc", marginLeft:"5px",marginRight:"20px", textAlign:"center" }}>
           <label className="formFieldLabel" htmlFor="email">
             Objetivo
           </label>
           <input
            style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
             type="number"
             id="objetivo"
             placeholder={edit_objetivo}
             name="objetivo"
             value={objetivo}
             //required
             onChange={e => setObjetivo(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>
 
 
       
 
         </HStack>
 
 
 
         <HStack marginBottom="1.2pc" justifyContent="space-between">
         <div style={{ width: "7.5pc", marginRight:"10px", textAlign:"center",  }}>
           <label className="formFieldLabel" htmlFor="email">
           Stop
           </label>
           <input
            style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
             type="number"
             id="stoploss"
             placeholder={edit_stoploss}
             name="stoploss"
             value={stoploss}
             //required
             onChange={e => setStoploss(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>
        
        
 
     
 
         <div className="formField" style={{ width: "7.5pc", marginLeft:"5px",marginRight:"5px", textAlign:"center" }}>
           <label className="formFieldLabel" htmlFor="email">
           Precio
           </label>
           <input
            style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
             type="number"
             id="precio"
             placeholder={edit_precio}
             name="precio"
             value={precio}
             //required
             onChange={e => setPrecio(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>
 
 
         <div className="formField"    style={{ width: "7.5pc", marginLeft:"5px",marginRight:"20px", textAlign:"center" }}>
           <label className="formFieldLabel" htmlFor="email">
           Garantia 
           </label>
           <input
           style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
             type="text"
             id="garantia"
             placeholder={edit_garantia}
             name="garantia"
             value={garantia}
             //required
             onChange={e => setGarantia(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>
 
       
 
         </HStack>
 
        
 
         <HStack marginBottom="1.2pc" justifyContent="space-between">
 
         <div  style={{ width: "7.5pc", marginLeft:"0px",marginRight:"5px", textAlign:"center" }}>
           <label className="formFieldLabel" htmlFor="email">
             Multi
           </label>
           <input
            style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
             type="number"
             id="multi"
             placeholder={edit_multi}
             name="multi"
             value={multi}
             //required
             onChange={e => setMulti(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>
 
 
     
         <div className="formField" style={{ width: "7.5pc", marginRight:"0px", textAlign:"center" }}>
           <label className="formFieldLabel" htmlFor="email">
            Sentido
           </label>
           <select  style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
           value={sentido}
           onChange={e => setSentido(e.target.value)}
             >
             <option value={1}>Alcista</option>
             <option value={2}>Bajista</option>
           </select>
         </div>
 
         <div className="formField" style={{ width: "7.5pc", marginRight:"20px", textAlign:"center" }}>
           <label className="formFieldLabel" htmlFor="email">
             F.Entrada
           </label>
           <input
            style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
             type="text"
             id="fent"
             placeholder={edit_fent}
             name="fent"
             value={fent}
             //required
             onChange={e => setFent(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>
        
        
        </HStack>
               
         {1==2&&<div className="formField"    style={{ width: "7.5pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
           <label className="formFieldLabel" htmlFor="email">
             Time Entrada
           </label>
           <input
           style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
             type="number"
             id="temp"
             placeholder={edit_temp}
             name="temp"
             value={temp}
             //required
             onChange={e => setTemp(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>}
 
 
         <HStack marginBottom="1.2pc" justifyContent="space-between">
 
         <div  style={{ width: "7.5pc", marginLeft:"0px",marginRight:"5px", textAlign:"center" }}>
           <label className="formFieldLabel" htmlFor="email">
             Ticker
           </label>
           <input
            style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
             type="text"
             id="symbol"
             placeholder={edit_symbol}
             name="symbol"
             value={symbol}
             //required
             onChange={e => setSymbol(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>
 
 
       
 
 <div className="formField" style={{ width: "7.5pc", marginLeft:"0px",marginRight:"5px", textAlign:"center" }}>
           <label className="formFieldLabel" htmlFor="email">
             Currency
           </label>
           <input
            style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
             type="text"
             id="currency"
             placeholder={edit_currency}
             name="currency"
             value={currency}
             //required
             onChange={e => setCurrency(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>
 
         <div className="formField" style={{ width: "7.5pc", marginLeft:"0px",marginRight:"20px", textAlign:"center" }}>
           <label className="formFieldLabel" htmlFor="email">
             Moneda
           </label>
           <input
            style={{ width: "7.5pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
             type="text"
             id="m"
             placeholder={edit_m}
             name="m"
             value={m}
             //required
             onChange={e => setM(e.target.value)}
             //value={this.state.email}
             //onChange={this.handleChange
           />
         </div>
         
         </HStack>
 
 
         
         
         <div style={{    textAlign:"center" }}>
         <HStack justifyContent="space-between" m={4} mt={10} mb={6}>
         
           <button className="mobformFieldOutlineButton"  _hover={{ cursor: "pointer" }} 
           onClick={() => cancelar()}
           style={{  width:"70%", textAlign:"center",   marginRight:"5%",fontWeight:700 }}>
           CANCELAR</button>
           
           <button className="mobformFieldFilledButton"  _hover={{ cursor: "pointer" }} 
           onClick={() => login()}
           style={{  textAlign:"center", marginLeft:"5%", marginRight:"5%" }}>
           ACTUALIZAR</button>
           
         </HStack>
         </div>
         
 
      
       
        
      
     
     </GridItem>
     </SimpleGrid>
   );
   }
   
   else
     {
    return (
      <Box ml={8} mr={8}>
        <input
          style={{
            fontSize: "1.2em",
            fontWeight: "bold",
            marginBottom: "1pc",
            marginTop: "-0.3pc",
            textAlign: "center",
            width: "96%",
          }}
          value={stock}
          onChange={(e) => setStock(e.target.value)}
        />
    
        <HStack marginBottom="1.2pc" justifyContent="space-between">
          <div style={{ width: "8pc", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="entrada">Entrada</label>
            <input
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              type="number"
              id="entrada"
              placeholder={edit_entrada}
              name="entrada"
              value={entrada}
              onChange={(e) => setEntrada(e.target.value)}
            />
          </div>
    
          <div className="formField" style={{ width: "8pc", marginLeft: "10px", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="titulos">Títulos</label>
            <input
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              type="text"
              id="titulos"
              placeholder={edit_titulos}
              name="titulos"
              value={titulos}
              onChange={(e) => setTitulos(e.target.value)}
            />
          </div>
    
          <div className="formField" style={{ width: "8pc", marginLeft: "10px", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="objetivo">Objetivo</label>
            <input
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              type="number"
              id="objetivo"
              placeholder={edit_objetivo}
              name="objetivo"
              value={objetivo}
              onChange={(e) => setObjetivo(e.target.value)}
            />
          </div>
    
          <div className="formField" style={{ width: "8pc", marginLeft: "10px", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="stoploss">Stop</label>
            <input
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              type="number"
              id="stoploss"
              placeholder={edit_stoploss}
              name="stoploss"
              value={stoploss}
              onChange={(e) => setStoploss(e.target.value)}
            />
          </div>
        </HStack>
    
        <HStack marginBottom="1.2pc" justifyContent="space-between">
          <div style={{ width: "8pc", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="precio">Precio</label>
            <input
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              type="number"
              id="precio"
              placeholder={edit_precio}
              name="precio"
              value={precio}
              onChange={(e) => setPrecio(e.target.value)}
            />
          </div>
    
          <div className="formField" style={{ width: "8pc", marginLeft: "10px", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="garantia">Garantia</label>
            <input
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              type="number"
              id="garantia"
              placeholder={edit_garantia}
              name="garantia"
              value={garantia}
              onChange={(e) => setGarantia(e.target.value)}
            />
          </div>
    
          <div className="formField" style={{ width: "8pc", marginLeft: "10px", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="multi">Multi</label>
            <input
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              type="number"
              id="multi"
              placeholder={edit_multi}
              name="multi"
              value={multi}
              onChange={(e) => setMulti(e.target.value)}
            />
          </div>
    
          <div className="formField" style={{ width: "8pc", marginLeft: "10px", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="sentido">Sentido</label>
            <select
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              value={sentido}
              onChange={(e) => setSentido(e.target.value)}
            >
              <option value={1}>Alcista</option>
              <option value={2}>Bajista</option>
            </select>
          </div>
        </HStack>
    
        <HStack marginBottom="1.2pc" justifyContent="space-between">
          <div style={{ width: "8pc", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="fent">F.Entrada</label>
            <input
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              type="text"
              id="fent"
              placeholder={edit_fent}
              name="fent"
              value={fent}
              onChange={(e) => setFent(e.target.value)}
            />
          </div>
    
          <div className="formField" style={{ width: "8pc", marginLeft: "10px", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="temp">Time Entrada</label>
            <input
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              type="number"
              id="temp"
              placeholder={edit_temp}
              name="temp"
              value={temp}
              onChange={(e) => setTemp(e.target.value)}
            />
          </div>
    
          <div className="formField" style={{ width: "8pc", marginLeft: "10px", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="symbol">Ticker</label>
            <input
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              type="text"
              id="symbol"
              placeholder={edit_symbol}
              name="symbol"
              value={symbol}
              onChange={(e) => setSymbol(e.target.value)}
            />
          </div>
    
          <div className="formField" style={{ width: "8pc", marginLeft: "10px", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="currency">Currency</label>
            <input
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              type="text"
              id="currency"
              placeholder={edit_currency}
              name="currency"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            />
          </div>
        </HStack>
    
        <HStack marginBottom="0pc" justifyContent="center">
          <div className="formField" style={{ width: "8pc", marginLeft: "10px", marginRight: "10px", textAlign: "center" }}>
            <label className="formFieldLabel" htmlFor="m">Moneda</label>
            <input
              style={{
                width: "8pc",
                borderRadius: "4px",
                border: "2px solid lightgray",
                textAlign: "center",
              }}
              type="text"
              id="m"
              placeholder={edit_m}
              name="m"
              value={m}
              onChange={(e) => setM(e.target.value)}
            />
          </div>
        </HStack>
    
        <HStack justifyContent="center" marginTop="1pc">
          <button
            type="button"
            className="formFieldOutlineButton"
            _hover={{ cursor: "pointer" }}
            onClick={() => cancelar()}
            style={{
              flex: 1,
              marginLeft: "4pc",
              marginRight: "2pc",
              width: "14pc",
              textAlign: "center",
              fontWeight: 700,
            }}
          >
            CANCELAR
          </button>
    
          <button
            type="button"
            className="formFieldFilledButton"
            _hover={{ cursor: "pointer" }}
            onClick={() => login()}
            style={{
              flex: 1,
              marginRight: "6pc",
              width: "14pc",
              textAlign: "center",
            }}
          >
            ACTUALIZAR
          </button>
        </HStack>
      </Box>
    );
    
  }
}

//export default EditPosTrader