import React, { useState } from 'react';
import './CookieConsentPopup.css';
import glovar from './glovar';
import CookiePolicy from './CookiePolicy';
import PrivacyPolicy from './PrivacyPolicy';
import TermsPolicy from './TermsPolicy';
import HeaderMenu from './HeaderMenu';
import FooterMenu from './FooterMenu';

import ShowAssets from './ShowAssets';
import ShowPopNewsletter from './ShowPopNewsletter';
import i18n from "../../i18n"
import { useFetchTodoRadar } from "../utils/useFetchTodoRadar";

import { SimpleGrid, GridItem, Text, Box, Stack, VStack, HStack,  Spacer, Flex } from "@chakra-ui/layout";
import { useRef, useEffect } from "react";

import { Image } from "@chakra-ui/image";
import eventBus from "../eventBus";

const  ShowRadar = ({ mode}) => {

  const [doRefresh, setDoRefresh] = useState(false);
  const [refresh, setRefresh] = useState(false);



  useEffect(() => {
    setRefresh(false);
    setDoRefresh(false);
  },[]);


  const ItemSibedar = ({ icon, active, id }) => (

      
      
    <Box
      _hover={{ cursor: "pointer" }}
      p={4}
      borderRadius="md"
      bg={(glovar.CURRENT_MENU_ID==id) ? "#000000" : "lightgray"}
      textColor={(glovar.CURRENT_MENU_ID==id) ? "white" : "gray"}
      onClick={() => {
        try {
          // EventGA("click_element","chgMenu_"+glovar.CURRENT_MENU_ID+"to "+id, id);  
        }
          catch (exx) { /*console.log("petada event:"+exx)*/}

        //this.changemenu(id)
        try {
          eventBus.dispatch("gotoChangeMenu", { id });
        }
        catch(Exx) {}

      }}
    >
      <Image src={icon} w={5}></Image>
    </Box>
  );

  const { todo, isFetching } = useFetchTodoRadar("day_gainers",0)
 
    if (!isFetching) 
    {
      //console.log("RADAR:"+JSON.stringify(todo));

      if(!doRefresh) 
      {
       setRefresh(true);
       setDoRefresh(true);
      }
    }
  
  else {
    //MY_LAST_SEARCH=glovar.LAST_SEARCH;
  }


  if(mode==0) {
    

    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      return(
        <div>
    <HeaderMenu showSearch={true} />
    <SimpleGrid columns={14} gap={1} p={0} mb={100} rounded={10}  pl={3} pr={3} backgroundColor="#F8F8F8">
     
    
    
        <GridItem colSpan={14} gap={1} justifyItems="center" rounded={10}   mt={8}  height="maxHeight">
        <Text textAlign="center" mt={0} fontSize="16" textColor="black" mb={2} fontFamily="Tahoma" fontWeight="semibold" > 
          Global Markets
    </Text>
        <Box rounded={12}   mt={0} p={1} height="maxHeight" background="#FFFFFF" width="98%">  
        <ShowAssets 
        arrayTickers={{label: "USA2", options:  glovar.TICKER_INDEX_USA}} 
        arrayCotis={process.env.REACT_APP_RADAR_1_1}
        anotherSearch={true} criteri={[{"label":"none","value":"none"}]} 
        //refreshFiltres={false} 
        noshowFilters={true} modeCompact={true} superCompact={true} varVertical={true}
      />  
         </Box>  
        </GridItem>

        
        <GridItem colSpan={14} gap={1} justifyItems="center" rounded={10}   mt={8}  height="maxHeight">
        <Text textAlign="center" mt={0} fontSize="16" textColor="black" mb={2} fontFamily="Tahoma" fontWeight="semibold" > 
        US Major Indexes
    </Text>
        <Box rounded={6}   mt={0} p={1} height="maxHeight" background="#FFFFFF" width="98%">  
        <ShowAssets 
        arrayTickers={{label: "USA2", options:  glovar.TICKER_INDEX_USA}} 
        arrayCotis={process.env.REACT_APP_RADAR_1_2}
        anotherSearch={true} criteri={[{"label":"none","value":"none"}]} 
        //refreshFiltres={false} 
        noshowFilters={true} modeCompact={true} superCompact={true} varVertical={true}
      />  
         </Box>  
        </GridItem>
    

        <GridItem colSpan={14} gap={1} justifyItems="center" rounded={10}   mt={8}  height="maxHeight">
        <Text textAlign="center" mt={0} fontSize="16" textColor="black" mb={2} fontFamily="Tahoma" fontWeight="semibold" > 
        Most Active stocks
    </Text>
        <Box rounded={6}   mt={0} p={1} height="maxHeight" background="#FFFFFF" width="98%">  
        {!isFetching&&<ShowAssets 
        arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
        arrayCotis={todo[2].symbols}// "most_actives" {process.env.REACT_APP_RADAR_1_3}
        anotherSearch={true} criteri={[{"label":"none","value":"none"}]} 
        //refreshFiltres={false} 
        noshowFilters={true} modeCompact={true} superCompact={true} varVertical={true}
      />} 
         </Box>  
        </GridItem>
      

        <GridItem colSpan={14} gap={1} justifyItems="center" rounded={10}   mt={8}  height="maxHeight">
        <Text textAlign="center" mt={0} fontSize="16" textColor="black" mb={2} fontFamily="Tahoma" fontWeight="semibold" > 
       Top Losers
    </Text>
        <Box rounded={6}   mt={0} p={1} height="maxHeight" background="#FFFFFF" width="98%">  
        {!isFetching&&<ShowAssets 
        arrayTickers={{label: "USA2", options:  glovar.TICKER_INDEX_USA}} 
        //arrayCotis={process.env.REACT_APP_RADAR_1_1}
        arrayCotis={todo[1].symbols}// "day_losers" 
        anotherSearch={true} criteri={[{"label":"none","value":"none"}]} 
        //refreshFiltres={false} 
        noshowFilters={true} modeCompact={true} superCompact={true} varVertical={true}
      />}
         </Box>  
        </GridItem>

        <GridItem colSpan={14} gap={1} justifyItems="center" rounded={10}   mt={8}  height="maxHeight">
        <Text textAlign="center" mt={0} fontSize="16" textColor="black" mb={2} fontFamily="Tahoma" fontWeight="semibold" > 
        Small Cap Gainers
    </Text>
        <Box rounded={6}   mt={0} p={1} height="maxHeight" background="#FFFFFF" width="98%">  
        {!isFetching&&<ShowAssets 
        arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
        //arrayCotis={process.env.REACT_APP_RADAR_1_2}
        arrayCotis={todo[3].symbols}// ""aggressive_small_caps"" 
        anotherSearch={true} criteri={[{"label":"none","value":"none"}]} 
        //refreshFiltres={false} 
        noshowFilters={true} modeCompact={true} superCompact={true} varVertical={true}
      />}
         </Box>  
        </GridItem>

        <GridItem colSpan={14} gap={1} justifyItems="center" rounded={10}   mt={8}  height="maxHeight">
        <Text textAlign="center" mt={0} fontSize="16" textColor="black" mb={2} fontFamily="Tahoma" fontWeight="semibold" > 
        Top Gainers
    </Text>
        <Box rounded={6}   mt={0} p={1} height="maxHeight" background="#FFFFFF" width="98%">  
       
    {!isFetching&&<ShowAssets 
        arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
        //arrayCotis={process.env.REACT_APP_RADAR_1_3}
        arrayCotis={todo[0].symbols}// "day gainers" 
        anotherSearch={true} criteri={[{"label":"none","value":"none"}]} 
        //refreshFiltres={false} 
        noshowFilters={true} modeCompact={true} superCompact={true} varVertical={true}
      /> }
         </Box>  
        </GridItem>
  
  
      
        <GridItem colSpan={14} gap={1} justifyItems="center" rounded={10}   mt={16} mb={150}>
      <ShowPopNewsletter/>
      </GridItem>
  


    </SimpleGrid>
    <FooterMenu/>
  </div>
      );
    }
    else {
  return (
    <div>
    <HeaderMenu showSearch={true} />
    
    <SimpleGrid columns={14} gap={1} p={2}  backgroundColor="#F8F8F8">
    <GridItem colSpan={1}>
    <Box mt={4}>
    <VStack>
      <ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_briefcase_white_48dp.png`} id={201} />
      {1==1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_compass_white_48dp.png`} id={13} />}
      {1==1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_data_search_48dp.png`} id={14} />}
      {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/baseline_notifications_white_48.png`} id={12} />}
      {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_podium_white_24dp.png`} id={202} />}
      {glovar.MODE_USER>=1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/baseline_account_box_white_48.png`} id={3} />}
     
      {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_chart_areaspline_white_24dp.png`} id={4} />}
    </VStack>
  </Box>
      </GridItem>
    <GridItem colSpan={13} mr={6}>

    <SimpleGrid   columns={12} gap={1} p={0} mt={6} mr={0} ml={0} pr={20} //pl={20} pr={20}
    paddingBottom={20} marginBottom={0} rounded={10} height="maxHeight"  backgroundColor="#F8F8F8">
       
     {1==2&&<GridItem colSpan={15} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={2} paddingBottom={6}>
        {1==2&&<Text textAlign="center" mt={0} fontSize="18" textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
          Pro1X RADAR
        </Text>}
     </GridItem>}

    <GridItem colSpan={4} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
    <Text textAlign="center" mt={0} fontSize="16" textColor="black" mb={2} fontFamily="Tahoma" fontWeight="semibold" > 
          Global Markets
    </Text>
     <Box rounded={6}   mt={0} p={0} mr={2} ml={2} height="maxHeight" background="#FFFFFF">  
      <ShowAssets 
        arrayTickers={{label: "USA2", options:  glovar.TICKER_INDEX_USA}} 
        arrayCotis={process.env.REACT_APP_RADAR_1_1}
        anotherSearch={true} criteri={[{"label":"none","value":"none"}]} 
        //refreshFiltres={false} 
        noshowFilters={true} modeCompact={true} superCompact={true} varVertical={true}
      />  
     </Box>  
    </GridItem>

    <GridItem colSpan={4} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
    <Text textAlign="center" mt={0} fontSize="16" textColor="black" mb={2} fontFamily="Tahoma" fontWeight="semibold" > 
          US Major Indexes
    </Text>
    <Box rounded={6}   mt={0} p={0} mr={2} ml={2} height="maxHeight" background="#FFFFFF">  
   
      <ShowAssets 
        arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
        arrayCotis={process.env.REACT_APP_RADAR_1_2}
        anotherSearch={true} criteri={[{"label":"none","value":"none"}]} 
        //refreshFiltres={false} 
        noshowFilters={true} modeCompact={true} superCompact={true} varVertical={true}
      />  
     </Box>  
    </GridItem>

    <GridItem colSpan={4} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
    <Text textAlign="center" mt={0} fontSize="16" textColor="black" mb={2} fontFamily="Tahoma" fontWeight="semibold" > 
          Most Active stocks
    </Text>
    <Box rounded={6}   mt={0} p={0} mr={2} ml={2} height="maxHeight" background="#FFFFFF">  
    
      {!isFetching && todo[2] && todo[2].symbols&&<ShowAssets 
        arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
        arrayCotis={todo[2].symbols}// "most_actives" {process.env.REACT_APP_RADAR_1_3}
        anotherSearch={true} criteri={[{"label":"none","value":"none"}]} 
        //refreshFiltres={false} 
        noshowFilters={true} modeCompact={true} superCompact={true} varVertical={true}
      />}
     </Box>  
    </GridItem>


    
</SimpleGrid> 

   
<SimpleGrid   columns={12} gap={1} p={0} ml={0} mr={0} pr={20} //pl={20} pr={20} 
paddingTop={0}  paddingBottom={10} marginBottom={10} rounded={10} height="maxHeight"  backgroundColor="#F8F8F8">
       
   

    <GridItem colSpan={4} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
    <Text textAlign="center" mt={0} fontSize="16" textColor="black" mb={2} fontFamily="Tahoma" fontWeight="semibold" > 
          Top Losers
    </Text>
     <Box rounded={6}   mt={0} p={0} mr={2} ml={2} height="maxHeight" background="#FFFFFF">  
      {!isFetching && todo[1] && todo[1].symbols&&<ShowAssets 
        arrayTickers={{label: "USA2", options:  glovar.TICKER_INDEX_USA}} 
        //arrayCotis={process.env.REACT_APP_RADAR_1_1}
        arrayCotis={todo[1].symbols}// "day_losers" 
        anotherSearch={true} criteri={[{"label":"none","value":"none"}]} 
        //refreshFiltres={false} 
        noshowFilters={true} modeCompact={true} superCompact={true} varVertical={true}
      />}
     </Box>  
    </GridItem>

    <GridItem colSpan={4} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
    <Text textAlign="center" mt={0} fontSize="16" textColor="black" mb={2} fontFamily="Tahoma" fontWeight="semibold" > 
     Small Cap Gainers
    </Text>
    <Box rounded={6}   mt={0} p={0} mr={2} ml={2} height="maxHeight" background="#FFFFFF">  
   
      {!isFetching && todo[3] && todo[3].symbols&&<ShowAssets 
        arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
        //arrayCotis={process.env.REACT_APP_RADAR_1_2}
        arrayCotis={todo[3].symbols}// ""aggressive_small_caps"" 
        anotherSearch={true} criteri={[{"label":"none","value":"none"}]} 
        //refreshFiltres={false} 
        noshowFilters={true} modeCompact={true} superCompact={true} varVertical={true}
      />}
     </Box>  
    </GridItem>

    <GridItem colSpan={4} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
    <Text textAlign="center" mt={0} fontSize="16" textColor="black" mb={2} fontFamily="Tahoma" fontWeight="semibold" > 
          Top Gainers
    </Text>
    <Box rounded={6}   mt={0} p={0} mr={2} ml={2} height="maxHeight" background="#FFFFFF">  
    

    
    {!isFetching && todo[0] && todo[0].symbols&&<ShowAssets 
        arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
        //arrayCotis={process.env.REACT_APP_RADAR_1_3}
        arrayCotis={todo[0].symbols}// "day gainers" 
        anotherSearch={true} criteri={[{"label":"none","value":"none"}]} 
        //refreshFiltres={false} 
        noshowFilters={true} modeCompact={true} superCompact={true} varVertical={true}
      /> }
     </Box>  
    </GridItem>

    

    
</SimpleGrid> 


<SimpleGrid columns={12} gap={1} p={2} mt={-1} mb={40} >
    <GridItem colStart={1} colEnd={12} ml={4} marginTop={0} marginBottom={0}> 
      
      <ShowPopNewsletter fitxaDesk={1}/>
      </GridItem>
      </SimpleGrid>
      </GridItem>
</SimpleGrid>

    <FooterMenu/>
  </div>
  );
  } 


  } 

  else if(mode==1) { // cookies

    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      return(
        <div>
    <HeaderMenu showSearch={true} />
    <SimpleGrid columns={14} gap={0} p={0} mb={300} height="maxHeight" backgroundColor="#FFFFFF">
      <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={0} paddingBottom={10}>
          <CookiePolicy />
      </GridItem>
    </SimpleGrid>
    <FooterMenu/>
  </div>
      );
    }
    else {
  return (
    <div>
    <HeaderMenu showSearch={true} />
    <SimpleGrid columns={14} gap={1} p={2} mb={300} height="maxHeight" backgroundColor="#FFFFFF">
    <GridItem colSpan={2} rounded={10}></GridItem>
      <GridItem colSpan={10} rounded={10} backgroundColor="#F8F8F8" p={12} m={2} mt={0} paddingBottom={10}>
      <HStack width="100%" flexDirection="row" justifyContent="center" mt={8}>
          <CookiePolicy />
        </HStack>
      
      </GridItem>
    </SimpleGrid>
    <FooterMenu/>
  </div>
  );
  } 
  }
  else if (mode==2) { // privacy

    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
    return (
      <div>
      <HeaderMenu showSearch={true} />
      <SimpleGrid columns={14} gap={0} p={0} mb={300} height="maxHeight" backgroundColor="#FFFFFF">
        <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={0} paddingBottom={10}>
        <HStack width="100%" flexDirection="row" justifyContent="center" mt={8}>
            <PrivacyPolicy />
          </HStack>
        
        </GridItem>
      </SimpleGrid>
      <FooterMenu/>
    </div>
    );
    }
    else {
      return (
        <div>
        <HeaderMenu showSearch={true} />
        <SimpleGrid columns={14} gap={1} p={2} mb={300} height="maxHeight" backgroundColor="#FFFFFF">
        <GridItem colSpan={2} rounded={10}></GridItem>
          <GridItem colSpan={10} rounded={10} backgroundColor="#F8F8F8" p={12} m={2} mt={0} paddingBottom={10}>
          <HStack width="100%" flexDirection="row" justifyContent="center" mt={8}>
              <PrivacyPolicy />
            </HStack>
          </GridItem>
        </SimpleGrid>
        <FooterMenu/>
      </div>
      );
    } 
    }
    else if (mode==3) { // terms
      if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        
      return (
        <div>
        <HeaderMenu showSearch={true} />
        <SimpleGrid columns={14} gap={0} p={0} mb={300} height="maxHeight" backgroundColor="#FFFFFF">
          <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={0} paddingBottom={10}>
          <HStack width="100%" flexDirection="row" justifyContent="center" mt={8}>
              <TermsPolicy />
            </HStack>
          
          </GridItem>
        </SimpleGrid>
        <FooterMenu/>
      </div>
      );
      } 
      else {
        return (
          <div>
          <HeaderMenu showSearch={true} />
          <SimpleGrid columns={14} gap={1} p={2} mb={300} height="maxHeight" backgroundColor="#FFFFFF">
          <GridItem colSpan={2} rounded={10}></GridItem>
            <GridItem colSpan={10} rounded={10} backgroundColor="#F8F8F8" p={12} m={2} mt={0} paddingBottom={10}>
            <HStack width="100%" flexDirection="row" justifyContent="center" mt={8}>
                <TermsPolicy />
              </HStack>
            
            </GridItem>
          </SimpleGrid>
          <FooterMenu/>
        </div>
        );
      }
      }

  }
  
  export default ShowRadar;